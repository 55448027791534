import * as Constants from '../constants'
import * as Account from '../account';

export async function _get(url) {
    var options = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        },
    }
    const response = await fetch(url, options);

    let status = response.status;

    if (status !== 200) {
        let text = await response.text()
        throw new Error(text);
    }

    let data = await response.json()
    return data;
}

export async function _authorizedRequest(method, resourceUrl, postBody) {
    return makerequest(method, resourceUrl,postBody, true)
}

export async function _request(method, resourceUrl, postBody) {
    return makerequest(method, resourceUrl,postBody, false)
}

async function makerequest(method, resourceUrl, postBody, authorizedRequest) {
    var acc = Account.getUserAccount();
    let headers = {
        'Content-Type': 'application/json',
    }

    if (authorizedRequest === true) {
        if (acc === null) {
            throw new Error("account is empty")
        }
        headers["Authorization"] = `Bearer ${acc.access_token}`;
    }

    var options = {
        method: method,
        headers: headers,
        body: postBody !== null && postBody !== undefined ? JSON.stringify(postBody) : null
    }

    const response = await fetch(Constants.API_BASE_URL + resourceUrl, options);
    let status = response.status;
    if (status === 401) {
        //unautorized
        //clear localStorage
        localStorage.removeItem(Account.accountSessionKey)
        window.location.href = "/"
        return null;
    }

    if (status !== 200) {
        let error = new Error();
        let e = await response.json()
        error = { ...error, data: e};
        throw error;
    }

    let data = await response.json()
    return data;
}
