
let _base_url = "https://viila.dev"
let _agent_url = "https://agent.viila.dev"
let _propez_url = "https://app.realtify.app"
let _google_client_id = "379613756082-57r2c458vvjajvgmr8ieqmbhrrs7059j.apps.googleusercontent.com"

if (process.env.REACT_APP_BUILD === 'prod') {
    _base_url = "https://viila.co"
    _agent_url = "https://agent.viila.co"
    _propez_url = "https://app.propertyeasy.com"
    // _google_client_id = ""
} else if (process.env.REACT_APP_BUILD === 'local') {
    _base_url = "http://localhost:8888"
    _agent_url = "https://agent.viila.dev"
    _propez_url = "https://app.realtify.app"
    // _google_client_id = ""
} else {
    _base_url = "https://viila.dev"
    _agent_url = "https://agent.viila.dev"
    _propez_url = "https://app.realtify.app"
    // _google_client_id = ""
}

export const GOOGLE_OAUTH2_CLIENT_ID = _google_client_id;
export const BASE_URL = `${_base_url}`;
export const API_BASE_URL = `${_base_url}/api`;
export const AGENT_URL = _agent_url
export const PROPEZ_URL = _propez_url
export const MAPBOX_TOKEN = 'pk.eyJ1IjoiYXBpc2l0dmlpbGEiLCJhIjoiY2t3NjdlMW83MngxMjJvcGFhN2d2cmNkNyJ9.K2ko2jB7iJ8o-_6C6WeNyw';
