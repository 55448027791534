import React from "react"
import { Link } from 'react-router-dom';
import * as Account from '../../account';
import LangContext from "../../LangContext";
import { Translate } from "../../translate/Translate";

export default function TopNavBar(props) {

  const [mobileMenuVisible, setMobileMenuVisible] = React.useState(false);
  const [account] = React.useState(Account.getUserAccount());

  const toggleMobileMenu = () => {
    setMobileMenuVisible(!mobileMenuVisible)
  }

  const logout = () => {
    Account.Logout();
    window.location.href = "/"
  }
  const { language, setLanguage } = React.useContext(LangContext)
  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  }

  return (
<div>
    <nav className="bg-indigo-700 bg-gradient-to-br from-indigo-700 to-indigo-800  lg:flex items-center w-full z-10 top-0 fixed">
      <div className="container px-4 mx-auto h-16 flex items-center justify-between flex-wrap ">
        <a href="/">
          <div className="flex items-center flex-shrink-0 mr-6">
            <svg className="fill-current text-white" width="32" height="30" viewBox="0 0 32 30" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 15.5471C0 16.2122 0.523303 16.7877 1.36059 16.7877C1.76615 16.7877 2.13246 16.5703 2.45953 16.3145L3.95094 15.0867V26.9815C3.95094 28.8745 5.11529 30 7.11693 30H24.8177C26.8062 30 27.9836 28.8745 27.9836 26.9815V15.0227L29.5536 16.3145C29.8675 16.5703 30.2339 16.7877 30.6394 16.7877C31.4113 16.7877 32 16.3145 32 15.5727C32 15.1378 31.8299 14.7925 31.4898 14.5111L27.9836 11.6205V6.1719C27.9836 5.59634 27.6043 5.23822 27.0155 5.23822H25.2101C24.6345 5.23822 24.242 5.59634 24.242 6.1719V8.55087L17.8708 3.31969C16.7326 2.38601 15.2935 2.38601 14.1554 3.31969L0.523303 14.5111C0.170074 14.7925 0 15.1762 0 15.5471ZM7.8103 27.4548C6.99918 27.4548 6.55437 27.0071 6.55437 26.2013V12.9507L15.4374 5.66029C15.8038 5.35333 16.2224 5.35333 16.5887 5.66029L25.3671 12.874V26.2013C25.3671 27.0071 24.9223 27.4548 24.1112 27.4548H7.8103Z" />
              <path d="M28.1328 1.96457C28.1328 3.04957 27.2331 3.92914 26.1233 3.92914C25.0135 3.92914 24.1138 3.04957 24.1138 1.96457C24.1138 0.879569 25.0135 0 26.1233 0C27.2331 0 28.1328 0.879569 28.1328 1.96457Z" />
            </svg>
            <span className=" ml-2 text-xl tracking-tight leading-none font-medium text-white">{Translate("Admin")}</span>
          </div>
        </a>
        <div className="block lg:hidden">
          <button onClick={(e) => toggleMobileMenu()} className="focus:outline-none flex items-center px-3 py-2 border rounded text-white border-white hover:text-white hover:border-white">
            {
              mobileMenuVisible ?
                <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
                :
                <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
            }
          </button>
        </div>

        <div className="w-full hidden flex-grow lg:flex lg:items-center lg:w-auto">
          <div className=" font-medium lg:flex-grow">
            <Link to="/" className={"block px-4 py-2 rounded mt-4 lg:inline-block lg:mt-0 hover:text-white mr-4 " + (props.active === 'dashboard' ? 'bg-opacity-50 bg-indigo-900 text-white' : 'text-indigo-400  hover:bg-indigo-600')}>{Translate("top_navbar_menu_dashboard")}</Link>
            <Link to="/lotnumber" className={"block px-4 py-2 rounded mt-4 lg:inline-block lg:mt-0 hover:text-white mr-4 " + (props.active === 'lotnumber' ? 'bg-opacity-50 bg-indigo-900 text-white' : 'text-indigo-400  hover:bg-indigo-600')}>{Translate("top_navbar_menu_lotnumber")}</Link>
            <Link to="/sellrequests" className={"block px-4 py-2 rounded mt-4 lg:inline-block lg:mt-0 hover:text-white mr-4 " + (props.active === 'sellrequests' ? 'bg-opacity-50 bg-indigo-900 text-white' : 'text-indigo-400  hover:bg-indigo-600')}>{Translate("top_navbar_menu_sellrequests")}</Link>
            <Link to="/example" className={"block px-4 py-2 rounded mt-4 lg:inline-block lg:mt-0 hover:text-white mr-4 " + (props.active === 'example' ? 'bg-opacity-50 bg-indigo-900 text-white' : 'text-indigo-400  hover:bg-indigo-600')}>{Translate("top_navbar_menu_homeexample")}</Link>
            <Link to="/company" className={"block px-4 py-2 rounded mt-4 lg:inline-block lg:mt-0 hover:text-white mr-4 " + (props.active === 'company' ? 'bg-opacity-50 bg-indigo-900 text-white' : 'text-indigo-400  hover:bg-indigo-600')}>{Translate("top_navbar_menu_company")}</Link>
            <Link to="/photo" className={"block px-4 py-2 rounded mt-4 lg:inline-block lg:mt-0 hover:text-white mr-4 " + (props.active === 'photo' ? 'bg-opacity-50 bg-indigo-900 text-white' : 'text-indigo-400  hover:bg-indigo-600')}>{Translate("top_navbar_menu_photo")}</Link>
            <Link to="/label" className={"block px-4 py-2 rounded mt-4 lg:inline-block lg:mt-0 hover:text-white mr-4 " + (props.active === 'label' ? 'bg-opacity-50 bg-indigo-900 text-white' : 'text-indigo-400  hover:bg-indigo-600')}>{Translate("top_navbar_menu_label")}</Link>
            <Link to="/interest" className={"block px-4 py-2 rounded mt-4 lg:inline-block lg:mt-0 hover:text-white mr-4 " + (props.active === 'interest' ? 'bg-opacity-50 bg-indigo-900 text-white' : 'text-indigo-400  hover:bg-indigo-600')}>{Translate("top_navbar_menu_interest")}</Link>
          </div>
          <div className="hidden lg:block">
            <div className="flex space-x-4 items-center">
              <div className="">
                <div className="text-base font-medium leading-none text-white">{account.user.first_name}&nbsp;{account.user.last_name}</div>
                <div className="text-sm font-medium  text-gray-400">{account.user.email}</div>
              </div>
              <div>
                <button className="text-white px-2 py-2 font-medium text-sm rounded border" onClick={(e) => logout()}>{Translate("Logout")}</button>
              </div>
              <div>
                <div className="inline-flex items-center">
                  <svg className="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                  </svg>
                  <select className="bg-transparent border-0 text-white focus:ring-0 font-medium text-sm" onChange={handleLanguageChange} value={language}>
                    <option value="en">{Translate("English")}</option>
                    <option value="ja">日本語</option>
                  </select>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile menu, toggle classes based on menu state.

      Open: "block", closed: "hidden" */}

      <div className={mobileMenuVisible === true ? "block" : "hidden"}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">

          <Link to="/" className={" block px-3 py-2 rounded-md text-base font-medium " + (props.active === "dashboard" ? " bg-indigo-900 text-white" : " text-gray-300 hover:bg-indigo-700 hover:text-white")}>{Translate("top_navbar_menu_dashboard")}</Link>
          <Link to="/lotnumber" className={" block px-3 py-2 rounded-md text-base font-medium " + (props.active === "lotnumber" ? " bg-indigo-900 text-white" : " text-gray-300 hover:bg-indigo-700 hover:text-white")}>{Translate("top_navbar_menu_lotnumber")}</Link>
          <Link to="/sellrequests" className={" block px-3 py-2 rounded-md text-base font-medium " + (props.active === "sellrequests" ? " bg-indigo-900 text-white" : " text-gray-300 hover:bg-indigo-700 hover:text-white")}>{Translate("top_navbar_menu_sellrequests")}</Link>
          <Link to="/example" className={" block px-3 py-2 rounded-md text-base font-medium " + (props.active === "example" ? " bg-indigo-900 text-white" : " text-gray-300 hover:bg-indigo-700 hover:text-white")}>{Translate("top_navbar_menu_homeexample")}</Link>
          <Link to="/company" className={" block px-3 py-2 rounded-md text-base font-medium " + (props.active === "company" ? " bg-indigo-900 text-white" : " text-gray-300 hover:bg-indigo-700 hover:text-white")}>{Translate("top_navbar_menu_company")}</Link>
          <Link to="/photo" className={" block px-3 py-2 rounded-md text-base font-medium " + (props.active === "photo" ? " bg-indigo-900 text-white" : " text-gray-300 hover:bg-indigo-700 hover:text-white")}>{Translate("top_navbar_menu_photo")}</Link>
          <Link to="/label" className={" block px-3 py-2 rounded-md text-base font-medium " + (props.active === "label" ? " bg-indigo-900 text-white" : " text-gray-300 hover:bg-indigo-700 hover:text-white")}>{Translate("top_navbar_menu_label")}</Link>
          <Link to="/interest" className={" block px-3 py-2 rounded-md text-base font-medium " + (props.active === "interest" ? " bg-indigo-900 text-white" : " text-gray-300 hover:bg-indigo-700 hover:text-white")}>{Translate("top_navbar_menu_interest")}</Link>
        </div>
        <div className="pt-4 pb-4 border-t border-indigo-700">
          <div className="flex items-center px-5">
            {/* <div className="flex-shrink-0">
              <img className="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
            </div> */}
            <div className="">
              <div className="text-base font-medium leading-none text-white">{account.user.first_name}&nbsp;{account.user.last_name}</div>
              <div className="text-sm font-medium  text-gray-400">{account.user.email}</div>
            </div>
          </div>

        </div>
      </div>
    </nav>
    {process.env.REACT_APP_BUILD !== 'prod' ? <div className="w-full bg-blue-500 mb-0 fixed text-xs text-center font-medium text-white py-1">{Translate("Staging environment")}</div> : null}
    </div>
  )
}