
import React from "react"
import { Translate } from "../../translate/Translate";
export function LabelGroupRow(props) {
    
    const [optionsVisible, setOptionsVisible] = React.useState(false);

    const toggleOptionsMenu = (e) => {
        setOptionsVisible(!optionsVisible)
    }

    return (

        <tr>
            <td className="px-6 py-4 text-center whitespace-no-wrap text-sm leading-5">
                <input type="checkbox" checked={props.checked} onChange={e=>props.onChange(props.data.id)} className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
                <div className="flex">
                {props.data.labels.map((label) => (<p className="bg-blue-50 m-1 font-semibold rounded text-indigo-700 flex">{label}</p>))}
                </div>
            </td>
            <td className="px-6 py-4 relative">
                <div className={"overflow-hidden origin-top-right z-50 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 " + (optionsVisible === true ? "" : "hidden")}>
                    <div className="bg-white" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <button type="button" onClick={(e) => { props.markAsDelete(props.data); toggleOptionsMenu() }} className="bg-white w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{Translate("Remove")}</button>
                        <button type="button" onClick={(e) => { toggleOptionsMenu() }} className="bg-white w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{Translate("Cancel")}</button>
                    </div>
                </div>
                <button onClick={(e) => { toggleOptionsMenu() }} className="z-10 focus:outline-none">
                    <svg className="fill-current text-gray-700" width="4" height="15" viewBox="0 0 4 15" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.86719 11.7188C1 11.7188 0.304688 12.4219 0.304688 13.3047C0.304688 14.1641 1 14.8672 1.86719 14.8672C2.73438 14.8672 3.42969 14.1641 3.42969 13.3047C3.42969 12.4219 2.73438 11.7188 1.86719 11.7188ZM1.86719 5.9375C1 5.9375 0.304688 6.63281 0.304688 7.5C0.304688 8.35938 1 9.05469 1.86719 9.05469C2.73438 9.05469 3.42969 8.35938 3.42969 7.5C3.42969 6.63281 2.73438 5.9375 1.86719 5.9375ZM1.86719 0.132812C1 0.132812 0.304688 0.828125 0.304688 1.69531C0.304688 2.57812 1 3.27344 1.86719 3.27344C2.73438 3.27344 3.42969 2.57812 3.42969 1.69531C3.42969 0.828125 2.73438 0.132812 1.86719 0.132812Z" />
                    </svg>
                </button>
            </td>
        </tr>

    )
}

