import React, {useState,useEffect} from "react"
import * as api from '../../services/admin'
import Select from 'react-select'
import TopNavBar from '../Shared/TopNavBar'
import { Link } from 'react-router-dom';
import { PropertyRow } from "../Shared/PropertyRow";
import { Translate } from "../../translate/Translate";
import { PropertyHistoriesModal } from "./PropertyHistoriesModal";


export default function Listing(props) {

    const [data, setData] = useState([]);
    const [properties, setProperties] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const selectStyles = {
        control: base => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: 'none'
        })
    };

    function loadProperties(list) {
        api.fetchProperties({list: list, status: "active", include_agent: true})
            .then((response) => {
                setModalOpen(true)
                console.log(response)
                setProperties(response)
            })
    }

    useEffect(() => {
        // loadProperties()
    }, [])


    function loadInterests() {
        api.interest()
            .then((response) => {
                console.log("loadInterests", response[0].user_preference)
                setData(response)
            })
    }

    useEffect(() => {
        console.log("!!!! changed")
        loadInterests()
    }, []);

    return (
        <div className="">
            <TopNavBar active="interest"></TopNavBar>
            <div className="mt-16 w-full container mx-auto py-8 px-4">
                <PropertyHistoriesModal {...props} isOpen={modalOpen} setIsOpen={setModalOpen} properties={properties} />
                <div className="mb-8 flex justify-between items-center">
                    <div>
                        <p className="text-2xl font-semibold">{Translate("Listing")}</p>
                    </div>
                </div>
                <div className="w-full">
                    <div className="flex justify-center items-center">
                        <div className="md:px-4 md:grid md:grid-cols-5 lg:grid-cols-5 gap-5 space-y-4 md:space-y-0">
                            <div>
                                <p class=" font-semibold mb-2">使用目的</p>
                                <div class="flex flex-col space-y-1">
                                    <div class="inline-flex items-center">
                                        <input id="pref-area-miyota" type="checkbox" value="miyota" class="focus:ring-blue-600 text-blue-600 rounded mr-2" />
                                        <label for="pref-area-miyota" class="block text-xs">御代田町</label>
                                    </div>
                                    <div class="inline-flex items-center">
                                        <input id="pref-area-komoro" type="checkbox" value="komoro" class="focus:ring-blue-600 text-blue-600 rounded mr-2" />
                                        <label for="pref-area-komoro" class="block  text-xs">小諸</label>
                                    </div>
                                
                                    <div class="inline-flex items-center">
                                        <input id="pref-area-tokyo" type="checkbox" value="tokyo" class="focus:ring-blue-600 text-blue-600 rounded mr-2" />
                                        <label for="pref-area-tokyo" class="block  text-xs">東京</label>
                                    </div>
                                
                                    <div class="inline-flex items-center">
                                        <input id="pref-area-saku" type="checkbox" value="saku" class="focus:ring-blue-600 text-blue-600 rounded mr-2" />
                                        <label for="pref-area-saku" class="block  text-xs">佐久</label>
                                    </div>
                                
                                    <div class="inline-flex items-center">
                                        <input id="pref-area-hakuba" type="checkbox" value="hakuba" class="focus:ring-blue-600 text-blue-600 rounded mr-2" />
                                        <label for="pref-area-hakuba" class="block  text-xs">白馬</label>
                                    </div>
                                
                                    <div class="inline-flex items-center">
                                        <input id="pref-area-karuizawa" type="checkbox" value="karuizawa" class="focus:ring-blue-600 text-blue-600 rounded mr-2" />
                                        <label for="pref-area-karuizawa" class="block  text-xs">軽井沢</label>
                                    </div>
                                
                                </div>
                            </div>
                            <div class="mt-6 col-span-2">
                                <p class=" font-semibold mb-2">お探しの物件種別</p>
                                <div class="lg:flex-row flex flex-col lg:space-x-2">
                                    <div class="inline-flex items-center">
                                        <input id="pref-new-home" type="checkbox" value="newly_built_home" class="focus:ring-blue-600 text-blue-600 rounded mr-2" />
                                        <label for="pref-new-home" class="block text-xs">新築の一戸建て</label>
                                    </div>
                                    <div class="inline-flex items-center">
                                        <input id="pref-used-home" type="checkbox" value="used_home" class="focus:ring-blue-600 text-blue-600 rounded mr-2" />
                                        <label for="pref-used-home" class="block text-xs">中古の一戸建て</label>
                                    </div>
                                
                                    <div class="inline-flex items-center">
                                        <input id="pref-new-apartment" type="checkbox" value="newly_built_apartment" class="focus:ring-blue-600 text-blue-600 rounded mr-2" />
                                        <label for="pref-new-apartment" class="block text-xs">新築マンション</label>
                                    </div>
                                    <div class="inline-flex items-center">
                                        <input id="pref-used-apartment" type="checkbox" value="used_apartment" class="focus:ring-blue-600 text-blue-600 rounded mr-2" />
                                        <label for="pref-used-apartment" class="block text-xs">中古マンション</label>
                                    </div>
                                    <div class="inline-flex items-center">
                                        <input id="pref-land" type="checkbox" value="land" class="focus:ring-blue-600 text-blue-600 rounded mr-2" />
                                        <label for="pref-land" class="block text-xs">土地</label>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-6">
                                <p class=" font-semibold mb-2">使用目的</p>
                                <div class="flex flex-col space-y-1">
                                    <div class="inline-flex items-center">
                                        <input id="pref-usage-settle" type="checkbox" value="place_to_settle" class="focus:ring-blue-600 text-blue-600 rounded mr-2" />
                                        <label for="pref-usage-settle" class="block text-xs">定住するための物件</label>
                                    </div>
                                    <div class="inline-flex items-center">
                                        <input id="pref-usage-villa" type="checkbox" value="second_home" class="focus:ring-blue-600 text-blue-600 rounded mr-2" />
                                        <label for="pref-usage-villa" class="block text-xs">別荘またはセカンドハウス</label>
                                    </div>
                                    <div class="inline-flex items-center">
                                        <input id="pref-usage-investment" type="checkbox" value="investment" class="focus:ring-blue-600 text-blue-600 rounded mr-2" />
                                        <label for="pref-usage-investment" class="block text-xs">投資用</label>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-6">
                                <p class=" font-semibold mb-2">Status</p>
                                <Select
                                    styles={selectStyles}
                                    className="text-base border border-gray-600 rounded "
                                    id="photoTypes"
                                    name="photoTypes"
                                    options={[]}
                                    getOptionLabel={({ name }) => name}
                                    getOptionValue={({ id }) => id}
                                    onChange={(value) => {
                                        // setFilters({...filters, type: value.id})
                                    }}
                                    value={[].filter(t => t.id)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="min-h-screen flex justify-center items-center">
                        <div className="md:px-4 md:grid md:grid-cols-2 lg:grid-cols-3 gap-5 space-y-4 md:space-y-0">
                            {data.map((user) => (<div key={user.id} className="max-w-sm bg-white px-6 pt-6 pb-2 rounded-xl shadow-lg transform transition duration-500">
                                {user.user_preference.send_page.length ? <div class="absolute top-0 right-0 p-4 z-20 cursor-pointer" onClick={() => {
                                    loadProperties(user.user_preference.send_page)
                                }}>
                                    <div class="flex flex-wrap">
                                        <div class="mr-2 mb-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs uppercase focus:outline-none bg-black bg-opacity-50">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                </svg>
                                            <span class="ml-1 font-semibold text-white text-xs">{user.user_preference.send_page.length}</span>
                                        </div>
                                    </div>
                                </div>: null}
                                <h3 className="mb-3 text-xl font-bold text-indigo-600">{user.last_name} {user.first_name}</h3>
                                <div className="flex space-x-1 items-center">
                                    <p>{user.email}</p>
                                </div>
                                <div className="my-4">
                                    <div className="flex space-x-1 items-center">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                            </svg>
                                        </span>
                                        <p>{user.user_preference.stage}</p>
                                    </div>
                                    <div className="flex space-x-1 items-center">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 8l3 5m0 0l3-5m-3 5v4m-3-5h6m-6 3h6m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </span>
                                        <p>{new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(user.user_preference.budget)}</p>
                                    </div>
                                    
                                    {user.user_preference.areas ? (<div>
                                        <label className="block text-sm leading-6">
                                            Areas
                                        </label>
                                        <div className="flex space-x-1 items-center">
                                            <div className="flex flex-wrap justify-starts items-center ">
                                                {(user.user_preference.areas || []).map((area, i) => (<div key={i} className="text-xs mr-2 py-1.5 px-4 text-gray-600 bg-blue-200 rounded-2xl">
                                                    {area}
                                                </div>))}
                                            </div>
                                        </div>
                                    </div>): null}
                                    {user.user_preference.purposes ? (<div>
                                        <label className="block text-sm leading-6">
                                            Purposes
                                        </label>
                                        <div className="flex space-x-1 items-center">
                                            <div className="flex flex-wrap justify-starts items-center ">
                                                {(user.user_preference.purposes || []).map((purpose, i) => (<div key={i} className="text-xs mr-2 py-1 px-2 text-gray-600 bg-blue-200 rounded-2xl">
                                                    {purpose}
                                                </div>))}
                                            </div>
                                        </div>
                                    </div>): null}
                                    {user.user_preference.looking_for_property ? (<div>
                                        <label className="block text-sm leading-6">
                                            Looking for property
                                        </label>
                                        <div className="flex space-x-1 items-center">
                                            <div className="flex flex-wrap justify-starts items-center ">
                                                {(user.user_preference.looking_for_property || []).map((propType, i) => (<div key={i} className="text-xs mr-2 py-1 px-2 text-gray-600 bg-blue-200 rounded-2xl">
                                                    {propType}
                                                </div>))}
                                            </div>
                                        </div>
                                    </div>): null}
                                    
                                </div>
                            </div>))}
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}