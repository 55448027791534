import React, { useEffect, useCallback, useRef, useState, Fragment } from "react"
import { MAPBOX_TOKEN } from '../../constants'

import * as turf from '@turf/turf'
import { throttle } from "lodash";
import 'mapbox-gl/dist/mapbox-gl.css';
import ReactMapGL, {
    Marker,
    // FlyToInterpolator,
    Popup,
    NavigationControl,
    FullscreenControl,
    ScaleControl,
    GeolocateControl,
    Source,
    Layer,
} from 'react-map-gl';

// added the following 6 lines.
import mapboxgl from 'mapbox-gl';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


const geolocateStyle = {
    bottom: 125,
    right: 0,
    padding: '0px'
};

const fullscreenControlStyle = {
    bottom: 160,
    right: 0,
    padding: '0px'
};

const navStyle = {
    bottom: 30,
    right: 0,
    padding: '0px'
};

const scaleControlStyle = {
    bottom: 24,
    left: 0,
    padding: '0px'
};


export default function MapView(props) {
    const [cursor, setCursor] = useState('auto')
    const {featureCollection} = props
    const query = new URLSearchParams(window.location.search);

    const mapRef = useRef()
    const [showHazardMap, setShowHazardMap] = useState(false)

    //====mapbox
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [selectedApartment, setSelectedApartment] = useState(null);
    const [viewport, setViewport] = useState({
        latitude: query.get("lat") ? parseFloat(query.get("lat")) : 36.3418518,
        longitude: query.get("lng") ? parseFloat(query.get("lng")) : 138.6179432,
        zoom: query.get("zoom") ? parseFloat(query.get("zoom")) : 13
    });

    const throttled = useCallback(

        throttle((newViewport) => {
            const query = new URLSearchParams(window.location.search);
            query.set("lat", newViewport.latitude.toString())
            query.set("lng", newViewport.longitude.toString())
            query.set("zoom", newViewport.zoom.toString())

            props.history.replace({
                pathname: window.location.pathname,
                search: decodeURIComponent(query.toString())
            })
        }, 1000), []);

    useEffect(() => {
        throttled(viewport)
    }, [viewport]);

    useEffect(() => {
        if (mapRef?.current && featureCollection.features.length > 0) {
            mapRef.current.fitBounds(turf.bbox(featureCollection), {padding: 50})

        }
    }, [featureCollection])



    const onViewportChange = (evt) => {
        setViewport(evt.viewState)
    }
    function toRadius(degrees) {
        return degrees * (Math.PI / 180);
    }
    


    const onMapClick = (e) => {

    }

    const onMapDblClick = (e) => {

    }

    function getCursor({ isHovering, isDragging }) {
        console.log("getCursor !!!")
        return isDragging ? 'grabbing' : isHovering ? 'pointer' : 'default';
    }

    return (
        <div className="flex flex-col w-full h-full relative">

            <div id="map" className=" h-full w-full bg-gray-500 relative">
                <ReactMapGL
                    ref={mapRef}
                    attributionControl={false}
                    onClick={onMapClick}
                    doubleClickZoom={false}
                    onDblClick={onMapDblClick}
                    cursor={getCursor}
                    mapStyle="mapbox://styles/apisitviila/ckwk55so8393b14mdforyhaxf"
                    mapboxAccessToken={MAPBOX_TOKEN}
                    {...viewport}
                    onMove={onViewportChange}
                    style={{ width: "100%", height: "100%" }}
                >

                {featureCollection?.features.length>0 ?
                    <Source id={`source-lotnumber`} type='geojson' data={featureCollection}>
                        <Layer
                            id={`layer-line-lotnumber`}
                            type='line'
                            source={`source-lotnumber`}
                            layout={{
                                'line-join': 'round',
                                'line-cap': 'round',
                            }}
                            paint={{
                                'line-color': "#1454ff",
                                'line-width': 5,
                            }}
                        />
                        <Layer
                            id={`layer-text-lotnumber`}
                            type="symbol"
                            source={`source-lotnumber`}
                            layout={{
                                "text-field": ["format",
                                    ["get", "id"], {},
                                ],
                                "text-font": [
                                    "Noto Sans CJK JP Bold",
                                    "Arial Unicode MS Regular"
                                ],
                                "text-size": 14
                            }}
                            minzoom={17}
                            paint={{
                                "text-halo-color": "#ffffff",
                                "text-halo-width": 1,
                                "text-color": "#181A1C"
                            }}

                        />
                    </Source>
                    :
                    null
                }
                    <ScaleControl position="bottom-right" style={scaleControlStyle} />
                    <FullscreenControl position="bottom-right" style={fullscreenControlStyle} />
                    <NavigationControl position="bottom-right" style={navStyle} />
                    <GeolocateControl position="bottom-right" style={geolocateStyle} />
                </ReactMapGL>

                <div className="absolute top-4 right-4 rounded-full bg-white px-3 py-1 shadow-xl">
                        <div className="flex items-center gap-4">
                            <div className="inline-flex items-center">
                                <input id="map-option-hazard-map" name="property-type" type="checkbox" className=" h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded" checked={showHazardMap} onChange={(e) => { setShowHazardMap(!showHazardMap) }} />
                                <label htmlFor="map-option-hazard-map" className="ml-2 block text-sm">show_hazard_map_button</label>
                            </div>
                        </div>
                </div>
            </div>



        </div>
    )
}