import React from "react"
import { PropertyItem } from "./PropertyItem"

export function PropertyHistoriesModal(props) {
    const {isOpen, setIsOpen, properties} = props

    return (
    <div class={`fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 py-2 ${isOpen ? "":"hidden"}`}
        id="my-modal">
        <div class="flex flex-col w-11/12 sm:w-5/6 mx-auto rounded-lg border border-gray-300 shadow-xl">
            <div class="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
                <p class="font-semibold text-gray-800">LabelGroup</p>
                <svg onClick={() => {
                    setIsOpen(false)
                }}
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                    ></path>
                </svg>
            </div>
            <div class="flex flex-col px-6 py-5 bg-gray-50 h-fullscreen">
                <div className="w-full flex">
                    <div id="list-container" class="grid w-full grid-cols-1 lg:grid-cols-3 gap-4">
                        {(properties.items || []).map((property) => <PropertyItem key={property.id} property={property} />)}
                    </div>
                </div>
            </div>
        </div>
    </div>)
}
