import React, {useState, useEffect} from "react"
import TopNavBar from '../Shared/TopNavBar'
import { Link } from 'react-router-dom';
import { CompanyRow } from "./CompanyRow";
import { Translate } from "../../translate/Translate";

export default function Listing(props) {

    const [keyword, setFilterKeyword] = useState("");
    const [data, setData] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    useEffect(() => {
        props.history.replace({
            pathname: window.location.pathname,
            search: `?keyword=${keyword}`
        })
    }, [keyword]);

    useEffect(() => {
        setCompanies(props.businessCompanies)
    }, [props.businessCompanies])

    const search = () => {
        setCompanies(keyword === "" ? props.businessCompanies: props.businessCompanies.filter((company) => {
            return company.name.includes(keyword) || company.company_no.includes(keyword) || company.phone.includes(keyword)
        }))
    }

    const handleAllChecked = (e) => {
        
        setCheckAll(e.target.checked)
        let items = []
        if (e.target.checked) {
            companies.forEach(k => items.push(k.id));
        } 
        setData(items)
    };

    const handleChecked = (id) => {
        let items = data
        const index = data.indexOf(id);
        if (index > -1) {
            items.splice(index, 1);
        } else {
            items.push(id)
        }
        setData(items)
    }

    return (
        <div className="">
            <TopNavBar active="company"></TopNavBar>
            <div className="mt-16 w-full container mx-auto py-8 px-4">
                <div className="mb-8 flex justify-between items-center">
                    <div>
                        <p className="text-2xl font-semibold">{Translate("Listing")}</p>
                    </div>
                    <Link to="/company/new" className="btn btn-primary inline-flex items-center">
                        <svg className="w-4 h-4 mr-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 13.8594C7.28906 13.8594 7.53125 13.625 7.53125 13.3359V7.90625H12.8047C13.0859 7.90625 13.3281 7.66406 13.3281 7.375C13.3281 7.08594 13.0859 6.84375 12.8047 6.84375H7.53125V1.40625C7.53125 1.11719 7.28906 0.882812 7 0.882812C6.71094 0.882812 6.46875 1.11719 6.46875 1.40625V6.84375H1.20312C0.914062 6.84375 0.671875 7.08594 0.671875 7.375C0.671875 7.66406 0.914062 7.90625 1.20312 7.90625H6.46875V13.3359C6.46875 13.625 6.71094 13.8594 7 13.8594Z" fill="white" />
                        </svg>
                        <span>{Translate("Add Property")}</span>
                    </Link>
                </div>

                <div id="filter-section" className="w-full mb-8 ">
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-6 lg:col-span-3">
                        </div>
                        <div className="col-span-6 lg:col-span-3">
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                            <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-600">{Translate("Search")}</label>
                            <div className="w-full inline-flex items-center">
                                <input value={keyword} onChange={(e) => {
                                    setFilterKeyword(e.target.value)
                                }} type="text" placeholder={Translate("search_placeholder")} className="rounded w-full"></input>
                                <button onClick={(e) => { 
                                    search()
                                }} className="flex-none ml-2 px-4 bg-gray-600 font-medium rounded py-2 text-white focus:outline-none">{Translate("Search")}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="pager" className="w-full">

                    <div className="mb-4 flex items-center justify-between">

                        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                            <div>
                            {companies.length === 0 ?
                                <p className="text-sm leading-5 text-gray-700">{Translate("No result")}</p>
                            :
                                <p className="text-sm leading-5 text-gray-700">
                                    {Translate("Showing")}<span className="font-medium px-1">{Translate("Total")} {companies.length}</span>{Translate("results")}
                                </p>
                            }
                            </div>
                        </div>
                        <div className="ml-auto">
                        </div>
                    </div>
                </div>

                <div className="w-full">

                    <div className="flex flex-col">
                        <div className="">
                            <div className="shadow rounded-md overflow-x-auto lg:overflow-visible">
                                <div className="">
                                    <table className="w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-100">
                                            <tr>
                                                <th className="px-2 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                                    <input checked={checkAll} type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" onChange={handleAllChecked}/>
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("Name")}</th>
                                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("Businesses")}</th>
                                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("Service Areas")}</th>
                                                <th className="px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("Date")}</th>
                                                <th className="px-6 py-3 bg-gray-50"></th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {companies === null ? "" : companies.map((property, index) => (
                                                <CompanyRow key={property.id} checked={data.findIndex((e) => e === property.id) !== -1} onChange={handleChecked} index={index} data={property}></CompanyRow>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}