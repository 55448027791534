import { useState, useEffect } from "react"
import { Link,useHistory } from 'react-router-dom';
import moment from 'moment'
import TopNavBar from '../Shared/TopNavBar'
import * as api from '../../services/admin'
import { Translate } from "../../translate/Translate";
import ApproveModal from '../../components/sellrequests/ApproveModal'
export default function SellRequest(props) {
    const [sellrequests, setSellrequests] = useState([])
    const [selected, setSelected] = useState(null)
    const [confirmOpen, setConfirmOpen] = useState(false)
    let history = useHistory();

    function loadSellRequests() {
        api.listSellrequests()
            .then(({data}) => {
                setSellrequests(data)
            })
    }

    const handleApprove = () => {
        api.approveSellrequest(selected)
            .then(() => {
                setConfirmOpen(false)
                history.push(`/sellrequests`);
            })
    }

    useEffect(() => {
        loadSellRequests()
    }, [])
    return (
    <div className="">
        <TopNavBar active="sellrequests"></TopNavBar>
        <div className="mt-16 w-full container mx-auto py-8 px-4">
            <div className="mb-8 flex justify-between items-center">
                <div>
                    <p className="text-2xl font-semibold">{Translate("Sell Requests")}</p>
                </div>
            </div>

            <div id="pager" className="w-full">

                <div className="mb-4 flex items-center justify-between">

                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div>
                        {sellrequests.length === 0 ?
                            <p className="text-sm leading-5 text-gray-700">{Translate("No result")}</p>
                        :
                            <p className="text-sm leading-5 text-gray-700">
                                {Translate("Showing")}<span className="font-medium px-1">{Translate("Total")} {sellrequests.length}</span>{Translate("results")}
                            </p>
                        }
                        </div>
                    </div>
                    <div className="ml-auto">
                    </div>
                </div>
            </div>

            <div className="w-full">

                <div className="flex flex-col">
                    <div className="">
                        <div className="shadow rounded-md overflow-x-auto lg:overflow-visible">
                            <div className="">
                                <table className="w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-100">
                                        <tr>
                                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("Request No")}</th>
                                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("Property Type")}</th>
                                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("Address")}</th>
                                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("Contact")}</th>
                                            <th className="px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("Request At")}</th>
                                            <th className="px-6 py-3 bg-gray-50"></th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {sellrequests.map((sr) => (<tr key={sr.id}>
                                            <td>{sr.sell_request_no}</td>
                                            <td>{sr.property_type}</td>
                                            <td>
                                                <p>{sr.data.full_address}</p>
                                            </td>
                                            <td>
                                                <p>{sr.contact.last_name} {sr.contact.first_name} ({sr.contact.email})</p>
                                                <p>{sr.contact.phone}</p>
                                            </td>
                                            <td>{moment(sr.created_at).format("LLL")}</td>
                                            <td className="px-6 py-4 flex gap-2">
                                                <Link to={'/sellrequests/' + sr.id} className="btn bg-gray-300">
                                                    View
                                                </Link>
                                                {sr.approved_at ? null: (<button onClick={(e) => { 
                                                    setConfirmOpen(true)
                                                    setSelected(sr.id)
                                                }} className="btn bg-blue-600 text-white">
                                                    approve
                                                </button>)}
                                            </td>
                                        </tr>))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <ApproveModal {...props} Translate={Translate} confirm={handleApprove} isOpen={confirmOpen} setIsOpen={setConfirmOpen}  />
    </div>)
}