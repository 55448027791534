import React, {useState,useEffect} from "react"
import * as api from '../../services/admin'
import TopNavBar from '../Shared/TopNavBar'
import { Link } from 'react-router-dom';
import { PropertyRow } from "../Shared/PropertyRow";
import { Translate } from "../../translate/Translate";

export default function Listing(props) {

    const [data, setData] = useState([]);
    const homes = props.homes.items;
    console.log("homes", homes)
    const [checkAll, setCheckAll] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    useEffect(() => {
        if (props.filters === null) {
            return;
        }
        props.history.replace({
            pathname: window.location.pathname,
            search: `?query=${JSON.stringify(props.filters)}`
        })
    }, [props.history, props.filters]);
    
    const markDelete = async (item) => {
        try {
            await api.deleteExample({homes: [item.id]})
            window.location.reload()
        } catch (error) {
            
        }
    }

    const handleAllChecked = (e) => {
        
        setCheckAll(e.target.checked)
        let items = []
        if (e.target.checked) {
            homes.forEach(k => items.push(k.id));
        } 
        setData(items)
    };

    const handleChecked = (id) => {
        let items = data
        const index = data.indexOf(id);
        if (index > -1) {
            items.splice(index, 1);
        } else {
            items.push(id)
        }
        console.log("handleChecked", items, id)
        setData([...items])
    }

    const markAsDelete = async () => {
        if (data.length === 0)
            return

        try {
            await api.deleteExample({homes: data})
        } catch (error) {
            
        }
    }

    const actionWithSelected = (e) => {
        let action = e.target.value;
        setSelectedAction(action)
        if (action === "remove") {
            markAsDelete()
        }
        //reset
        setData([])
        setCheckAll(false)
        setSelectedAction("")
        window.location.reload()

    }


    return (
        <div className="">
            <TopNavBar active="example"></TopNavBar>
            <div className="mt-16 w-full container mx-auto py-8 px-4">
                <div className="mb-8 flex justify-between items-center">
                    <div>
                        <p className="text-2xl font-semibold">{Translate("Listing")}</p>
                    </div>
                    <Link to="/example/steps" className="btn btn-primary inline-flex items-center">
                        <svg className="w-4 h-4 mr-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 13.8594C7.28906 13.8594 7.53125 13.625 7.53125 13.3359V7.90625H12.8047C13.0859 7.90625 13.3281 7.66406 13.3281 7.375C13.3281 7.08594 13.0859 6.84375 12.8047 6.84375H7.53125V1.40625C7.53125 1.11719 7.28906 0.882812 7 0.882812C6.71094 0.882812 6.46875 1.11719 6.46875 1.40625V6.84375H1.20312C0.914062 6.84375 0.671875 7.08594 0.671875 7.375C0.671875 7.66406 0.914062 7.90625 1.20312 7.90625H6.46875V13.3359C6.46875 13.625 6.71094 13.8594 7 13.8594Z" fill="white" />
                        </svg>
                        <span>{Translate("Add Property")}</span>
                    </Link>
                </div>

                <div id="filter-section" className="w-full mb-8 ">
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-6 lg:col-span-3">
                        </div>
                        <div className="col-span-6 lg:col-span-3">
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                            <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-600">{Translate("Search")}</label>
                            <div className="w-full inline-flex items-center">
                                <input value={props.keywords === null ? "" : props.keywords} onChange={props.handleFilterKeyword} type="text" placeholder={Translate("search_placeholder")} className="rounded w-full"></input>
                                <button onClick={(e) => { props.search() }} className="flex-none ml-2 px-4 bg-gray-600 font-medium rounded py-2 text-white focus:outline-none">{Translate("Search")}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="pager" className="w-full">

                    <div className="mb-4 flex items-center justify-between">

                        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                            <div>
                            {props.homes.total_item === 0 ?
                                <p className="text-sm leading-5 text-gray-700">{Translate("No result")}</p>
                            :
                                <p className="text-sm leading-5 text-gray-700">
                                    {Translate("Showing")}<span className="font-medium px-1">{props.homes.from}-{props.homes.to} {Translate("Total")} {props.homes.total_item}</span>{Translate("results")}
                                </p>
                            }
                            </div>
                        </div>
                        {
                            props.homes.total_page === 1 ? null :
                                <div>
                                    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                                        {
                                            props.homes.current_page === 1 ? null :
                                                <button onClick={(e) => props.handleFilterChange({ target: { name: "page", value: props.homes.current_page - 1 } })} className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                                    <span className="sr-only"> {Translate("Back")}</span>
                                                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                                    </svg>
                                                </button>
                                        }

                                        {Array.from(Array(props.homes.total_page), (e, i) => {
                                            return <button key={i} href="#" onClick={(e) => props.handleFilterChange({ target: { name: "page", value: i + 1 } })} className={(props.homes.current_page === i + 1 ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600" : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50") + "relative inline-flex items-center px-4 py-2 border text-sm font-medium"}>{i + 1}</button>
                                        })}
                                        {
                                            (props.homes.current_page === props.homes.total_page || props.homes.total_page === 0) ? null :
                                                <button href="#" onClick={(e) => props.handleFilterChange({ target: { name: "page", value: props.homes.current_page + 1 } })} className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                                    <span className="sr-only"> {Translate("Next")}</span>
                                                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                                    </svg>
                                                </button>
                                        }
                                    </nav>
                                </div>
                        }
                        <div className="ml-auto">
                            <select className="rounded text-xs" onChange={(e) => {actionWithSelected(e)}} value={selectedAction}>
                                <option value="">—</option>
                                <option value="remove">{Translate("multiple_select_action_delete")}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="w-full">

                    <div className="flex flex-col">
                        <div className="">
                            <div className="shadow rounded-md overflow-x-auto lg:overflow-visible">
                                <div className="">
                                    <table className="w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-100">
                                            <tr>
                                                <th className="px-2 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                                    <input checked={checkAll} type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" onChange={handleAllChecked}/>
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("Name")}</th>
                                                <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("Price")}</th>
                                                <th className="px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("Date")}</th>
                                                <th className="px-6 py-3 bg-gray-50"></th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {homes === null ? "" : homes.map((home, index) => (
                                                <PropertyRow key={home.id} checked={data.findIndex((e) => e === home.id) !== -1} markDelete={markDelete}  onChange={handleChecked} index={index} data={home}></PropertyRow>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}