import { useState, useEffect } from "react"
import { Link,useHistory, useParams } from 'react-router-dom';
import moment from 'moment'
import * as Constants from '../../constants'
import { Tab } from '@headlessui/react'
import TopNavBar from '../Shared/TopNavBar'
import * as api from '../../services/admin'
import { Translate } from "../../translate/Translate";
import ApproveModal from '../../components/sellrequests/ApproveModal'

export default function SellRequestDetail(props) {
    const { id } = useParams();
    const [sellRequest, setSellRequest] = useState({
        "id":"",
        "user_id":"",
        "property_type":"",
        "contact":{"first_name":"","last_name":"","email":"","phone":"","address_1":"","address_2":"","city":"","prefecture":"","zip_code":"","region":"","country_code":"","image_url":"","role":"","settings":{"language":""},"stripe_customer_id":""},
        "data":{"floor_plan":"","full_address":"","lot_sqm":"","property_owner":"","reason_to_sell":[],"request_to_agents":[],"room_sqm":"","same_address":false,"time_to_sell":""},
        "latlng":{"latitude":36.36351776123047,"longitude":138.5756378173828},
        "agents":[],
        "proposals":{},
        "activities":{},
        "created_at": new Date(), 
        "approved_at": new Date(), 
        "status":"pending",
        "proposal_amount":0,
        "request_no":""
    })
    const [confirmOpen, setConfirmOpen] = useState(false)
    let history = useHistory();

    function loadSellRequest(id) {
        api.fetchSellrequest(id)
            .then(({data}) => {
                setSellRequest(data)
            })
    }

    const handleApprove = () => {
        api.approveSellrequest(id)
            .then(() => {
                setConfirmOpen(false)
                history.push(`/sellrequests`);
            })
    }

    useEffect(() => {
        loadSellRequest(id)
    }, [id])
    return (
    <div className="">
        <TopNavBar active="sellrequests"></TopNavBar>
        <div className="mt-16 w-full container mx-auto py-8 px-4">
            <div className="mb-8 flex justify-between items-center">
                <div>
                    <p className="text-2xl font-semibold">{Translate("Sell Requests")}</p>
                    <p>No: {sellRequest.sell_request_no}</p>
                </div>
                <div className="ml-auto">
                    <div className="p-5 bg-gray-300 rounded">
                        <div className="text-lg font-medium flex flex-col items-center justify-center">
                            <p>{sellRequest.status} - {sellRequest.state}</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="w-full">
                <Tab.Group defaultIndex={0}>
                    <Tab.List className="w-full flex gap-1 items-center justify-between rounded-lg bg-slate-200 overflow-hidden p-1 mb-1">
                        <Tab className="flex items-center justify-center w-full focus:outline-none">
                            {({ selected }) => (
                                <div className={`rounded-lg w-full h-full py-2 text-sm font-medium 
                                    ${selected ? 'text-primary bg-white shadow' : 'text-slate-400 hover:bg-slate-100/60'}
                                    `}>
                                    Sell Requests
                                </div>
                            )}</Tab>
                        <Tab className="flex items-center justify-center w-full focus:outline-none">
                            {({ selected }) => (
                                <div className={`rounded-lg w-full h-full py-2 text-sm font-medium 
                                    ${selected ? 'text-primary bg-white shadow' : 'text-slate-400 hover:bg-slate-100/60'}
                                    `}>
                                    Contact
                                </div>
                            )}
                        </Tab>
                        <Tab className="flex items-center justify-center w-full focus:outline-none">
                            {({ selected }) => (
                                <div className={`rounded-lg w-full h-full py-2 text-sm font-medium 
                                    ${selected ? 'text-primary bg-white shadow' : 'text-slate-400 hover:bg-slate-100/60'}
                                    `}>
                                    Property Info
                                </div>
                            )}</Tab>
                        <Tab className="flex items-center justify-center w-full focus:outline-none">
                            {({ selected }) => (
                                <div className={`rounded-lg w-full h-full py-2 text-sm font-medium 
                                    ${selected ? 'text-primary bg-white shadow' : 'text-slate-400 hover:bg-slate-100/60'}
                                    `}>
                                    Activity
                                </div>
                            )}</Tab>
                        <Tab className="flex items-center justify-center w-full focus:outline-none">
                            {({ selected }) => (
                                <div className={`rounded-lg w-full h-full py-2 text-sm font-medium 
                                    ${selected ? 'text-primary bg-white shadow' : 'text-slate-400 hover:bg-slate-100/60'}
                                    `}>
                                    Proposal
                                </div>
                            )}</Tab>
                    </Tab.List>
                    <Tab.Panels>
                        <Tab.Panel>
                            {Object.keys(sellRequest).filter((key) => !["contact", "data", "activities", "proposals"].includes(key)).map((key) => {
                                return (<div className="flex border-b border-dotted border-gray-400 py-1" key={key}>
                                <p className="text-gray-700 w-1/3 flex-none">{key}</p>
                                <p className="font-medium" id="summary-step-0-0-full_address">{JSON.stringify(sellRequest[key])}</p>
                            </div>)
                            })}
                        </Tab.Panel>
                        <Tab.Panel>
                            {Object.keys(sellRequest["contact"]).map((key) => {
                                return (<div className="flex border-b border-dotted border-gray-400 py-1" key={key}>
                                    <p className="text-gray-700 w-1/3 flex-none">{key}</p>
                                    <p className="font-medium" id="summary-step-0-0-full_address">{JSON.stringify(sellRequest["contact"][key])}</p>
                            </div>)})}
                        </Tab.Panel>
                        <Tab.Panel>
                            {Object.keys(sellRequest["data"]).map((key) => {
                                return (<div className="flex border-b border-dotted border-gray-400 py-1" key={key}>
                                <p className="text-gray-700 w-1/3 flex-none">{key}</p>
                                <p className="font-medium" id="summary-step-0-0-full_address">{JSON.stringify(sellRequest["data"][key])}</p>
                            </div>)
                            })}
                        </Tab.Panel>
                        <Tab.Panel>
                            {Object.keys(sellRequest["activities"]).map((agentNo) => {
                                return (<div className="p-4 bg-white rounded-lg shadow mb-4">
                                    <div className="items-center gap-4">
                                        <div class="">
                                            <div class="flex items-center gap-2 text-sm font-medium mt-1">
                                                <div class="flex items-center gap-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="w-4 h-4 text-slate-500"><path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"></path></svg>
                                                    <span>{agentNo}</span>
                                                </div>
                                                <a href={`${Constants.BASE_URL}/a/${agentNo}`} target="_blank" class="flex items-center gap-1 text-primary"><span>View on Viila</span> 
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="w-4 h-4 text-primary"><path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"></path></svg>
                                                </a>
                                            </div>
                                            <p className="p-2">Activity</p>
                                            <div className="py-1 overflow-y-auto max-h-80 bg-gray-200 p-4">
                                                {sellRequest["activities"][agentNo].map((activity, key) => (<div className="flex p-4 border" key={key}>
                                                    <div className="flex flex-col lg:flex-row items-center gap-4">
                                                        <div className="relative w-16 h-16 rounded-full bg-slate-200 border border-slate-300  flex items-center justify-center overflow-hidden">
                                                            <img className="w-full h-full object-cover" src={activity.user.image_url} />
                                                        </div>
                                                        <p>{activity.user.last_name} {activity.user.first_name}</p>
                                                        <p className="font-medium whitespace-pre-wrap ">{activity.activity}</p>
                                                    </div>
                                                    <div className="ml-auto">
                                                        <p>{moment(activity.created_at).format("LLL")}</p>
                                                    </div>
                                                </div>))}
                                            </div>
                                        </div>

                                    </div>
                                    
                                </div>)
                            })}
                        </Tab.Panel>
                        <Tab.Panel>
                            {Object.keys(sellRequest["proposals"]).map((agentNo) => {
                                return (<div className="p-4 bg-white rounded-lg shadow mb-4">
                                    {sellRequest["proposals"][agentNo].map((proposal) => (<div className="items-center gap-4">
                                        <div class="">
                                            <div class="flex items-center text-sm font-medium text-slate-500 gap-4">
                                                <div class="flex items-center gap-1">
                                                    <p>{proposal.user.last_name} {proposal.user.first_name} ({proposal.user.email})</p>
                                                </div>
                                                <div className="ml-auto">
                                                    <p>Proposal No.:{proposal.proposal_no}</p>
                                                    <p>Status:{proposal.status}</p>
                                                    <p>Proposed at: {moment(proposal.proposed_at).format("LLL")}</p>
                                                </div>
                                            </div>
                                            <div class="flex items-center gap-2 text-sm font-medium mt-1">
                                                <div class="flex items-center gap-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="w-4 h-4 text-slate-500"><path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"></path></svg>
                                                    <span>{agentNo}</span>
                                                </div>
                                                <a href={`${Constants.BASE_URL}/a/${agentNo}`} target="_blank" class="flex items-center gap-1 text-primary"><span>View on Viila</span> 
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="w-4 h-4 text-primary"><path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"></path></svg>
                                                </a>
                                            </div>
                                            <div class="flex items-center gap-4 text-xs mt-1">
                                                <div className="flex flex-col py-1">
                                                    <p className="text-gray-700 w-1/3 flex-none">Price</p>
                                                    <p className="font-medium" id="summary-step-0-0-full_address">{new Intl.NumberFormat('ja-JP',{ style: 'currency', currency: "JPY" }).format(Number(proposal.min_price?.amount || 0))} - {new Intl.NumberFormat('ja-JP',{ style: 'currency', currency: "JPY" }).format(Number(proposal.max_price?.amount || 0))}</p>
                                                </div>
                                                <div className="flex flex-col py-1">
                                                    <p className="text-gray-700 w-1/3 flex-none">Commission</p>
                                                    <p className="font-medium" id="summary-step-0-0-full_address">{proposal.commission}%</p>
                                                </div>
                                                <div className="flex flex-col py-1">
                                                    <p className="text-gray-700 w-1/3 flex-none">Views</p>
                                                    <p className="font-medium" id="summary-step-0-0-full_address">{Number(proposal.number_of_view || 0)}</p>
                                                </div>
                                            </div>
                                            <p className="p-2">Remarks</p>
                                            <div className="py-1 flex overflow-y-auto max-h-80 bg-gray-200 p-4">
                                                <p className="font-medium whitespace-pre-wrap ">{proposal.remarks}</p>
                                            </div>
                                        </div>
                                    </div>))}
                                </div>)
                            })}
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>

        </div>
        <ApproveModal {...props} Translate={Translate} confirm={handleApprove} isOpen={confirmOpen} setIsOpen={setConfirmOpen}  />
    </div>)
}