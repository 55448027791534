import React from "react"
import { Translate } from "../../translate/Translate";

export default function TablePagination(props) {
    if (props.data.total_page === 1) {
        return null
    }
    return (
    <div className="mb-4 flex items-center justify-between">

        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
            {props.data.items === 0 ?
                <p className="text-sm leading-5 text-gray-700">{Translate("No result")}</p>
            :
                <p className="text-sm leading-5 text-gray-700">
                {Translate("Showing")}<span className="font-medium px-1">{props.data.from}-{props.data.to} {Translate("Total")} {props.data.total_item}</span>{Translate("results")} 
                </p>
            }
            </div>
        </div>
        {props.data.total_page === 1 ? null : (
        <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                {
                    props.data.current_page === 1 ? null :
                        <button onClick={(e) => props.handleFilterChange({ target: { name: "page", value: props.data.current_page - 1 } })} className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                            <span className="sr-only"> {Translate("Back")}</span>
                            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                            </svg>
                        </button>
                }

                {Array.from(Array(5).keys()).filter((_,i) => i+1 < props.data.current_page).map((e, i) => (<button key={i} href="#" onClick={(e) => props.handleFilterChange({ target: { name: "page", value: i + 1 } })} className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium">{i + 1}</button>))}
                {7 - props.data.current_page < 0 ? <button href="#" className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium">...</button>: null}
                
                {6 - props.data.current_page < 0 ? <button href="#" onClick={(e) => props.handleFilterChange({ target: { name: "page", value: props.data.current_page-1 } })} className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium">{props.data.current_page-1}</button>: null}
                <button id="current-page" href="#" className="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium">{props.data.current_page}</button>
                {props.data.total_page - props.data.current_page > 5 ? <button href="#" onClick={(e) => props.handleFilterChange({ target: { name: "page", value: props.data.current_page+1 } })}  className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium">{props.data.current_page+1}</button>: null}
                
                {props.data.total_page - props.data.current_page > 6 ? <button href="#" onClick={(e) => props.handleFilterChange({ target: { name: "page", value: props.data.current_page+1 } })}  className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium">...</button>: null}
                {Array.from(Array(5).keys()).reverse().filter((i) => props.data.total_page - i > props.data.current_page).map((i) => (<button key={i} href="#" onClick={(e) => props.handleFilterChange({ target: { name: "page", value: props.data.total_page - i } })} className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium">{props.data.total_page - i}</button>))}
                
                {
                    (props.data.current_page === props.data.total_page || props.data.total_page === 0) ? null :
                        <button href="#" onClick={(e) => props.handleFilterChange({ target: { name: "page", value: props.data.current_page + 1 } })} className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                            <span className="sr-only"> {Translate("Next")}</span>
                            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                            </svg>
                        </button>
                }
            </nav>
        </div>
        )}
        <div className="ml-auto">
        </div>
    </div>)

}