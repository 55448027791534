import React, {useState} from "react"
import { useEffect } from "react";
import * as api from '../../services/admin'
import { Translate } from "../../translate/Translate";
import * as Constants from '../../constants'
import TablePagination from '../Shared/TablePagination'
import Error from '../Shared/Error'


export function LabelGroupModal(props) {
    const selectStyles = {
        control: base => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: 'none'
        })
    };

    const {filters, labels, isOpen, setIsOpen, tag} = props
    const [showError, setShowError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
        const [filterKeyword, setFilterKeyword] = useState('')
    const [photos, setPhotos] = useState({
        items:[], 
        total_item: 0,
        total_page: 0,
        current_page: 0,
        page_size: 0,
        from: 0,
        to: 0,
    })

    function handleFilterChange({target}) {
        // { target: { name: "page", value: photos.current_page - 1 } }
        const { name, value } = target
        props.setFilters({ ...filters, [name]: value })
    }

    function loadPhotos(selected) {
        api.fetchHomePhotos(selected)
            .then((response) => {
                setPhotos(response)
            })
    }

    function unique(array) {
        return [...new Set(array)];
    }

    function addLabelGroup(newGroup) {
        api.createTagLabel({
            name: tag.name,
            label_group: unique([...tag.label_group, filters.labels]),
        }).then((response) => {
            props.setFilters({ ...filters, labels: [], photo_forward: undefined, page: 1})
            setIsOpen(false)
            window.location.reload()
        })
        .catch(error => {
            setShowError(true)
            setErrorMessage(error.data.message)
        })
    }

    useEffect(() => {
        loadPhotos({page:1, page_size:100, type:"home", labels: filters.labels})
    }, [filters.labels])

    return (
    <div class={`fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 py-2 ${isOpen ? "":"hidden"}`}
        id="my-modal">
        <div class="flex flex-col w-11/12 sm:w-5/6 mx-auto rounded-lg border border-gray-300 shadow-xl">
            <div class="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
                <p class="font-semibold text-gray-800">LabelGroup</p>
                <svg onClick={() => {
                    setIsOpen(false)
                    props.setFilters({...filters, labels: [], photo_forward: undefined})
                }}
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                    ></path>
                </svg>
            </div>
            <div class="flex flex-col px-6 py-5 bg-gray-50 h-fullscreen">
                <div className="w-full flex">
                    <div className="flex-col w-2/6">
                        <p class="mb-2 font-semibold text-gray-700">Labels</p>
                        <div>
                            <p className="px-3 py-2 rounded text-sm bg-blue-600 text-white inline-block mr-1 mb-1 cursor-default">
                                {filters.tag}
                            </p>
                        </div>
                        <p class="mb-2 font-semibold text-gray-700">Labels</p>
                        <div>
                            {filters.labels.map((select) => (
                                <p key={select} className="px-3 py-2 rounded text-sm bg-blue-600 text-white inline-block mr-1 mb-1 cursor-default">
                                    {select} 
                                    <span className="my-auto ml-2 inline-flex cursor-pointer" onClick={(e) => { 
                                        let index = filters.labels.findIndex(item => item === select)
                                        if (index !== -1) {
                                            filters.labels.splice(index, 1)
                                            props.setFilters({...filters, labels: [...filters.labels], page: 1})
                                        }
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                    </span>
                                </p>
                            ))}
                        </div>
                    </div>
                    <div className="w-4/6 ml-4 flex-col">
                        <div class="w-full inline-flex items-center mb-2">
                            <p className="text-2xl font-semibold">{Translate("Labels")}</p>
                            <input type="text" placeholder="filter keyword" class="rounded w-full ml-2" value={filterKeyword} onChange={(e) => {
                                setFilterKeyword(e.target.value)
                            }} />
                        </div>
                        <div className="h-64 overflow-auto">
                            {labels.filter(({description}) => description.toLowerCase().includes(filterKeyword))
                                .map((label) => <p key={label.description} onClick={() => {
                                let index = filters.labels.findIndex(item => item === label.description)
                                if (index === -1) {
                                    props.setFilters({...filters, labels: [...filters.labels, label.description], page: 1})
                                } else {
                                    filters.labels.splice(index, 1)
                                    props.setFilters({...filters, labels: [...filters.labels], page: 1})
                                }
                            }} className="px-3 py-2 rounded text-sm bg-blue-600 text-white inline-block mr-1 mb-1 cursor-pointer">{label.description} ({label.count})</p>)}
                        </div>
                    </div>
                </div>
                <div
                class="flex flex-row items-center justify-between p-5  rounded-bl-lg rounded-br-lg"
                >
                    <p class="font-semibold text-gray-600">Cancel</p>
                    <button class="px-4 py-2 text-white font-semibold bg-blue-500 rounded" onClick={() => {
                        addLabelGroup()
                    }}>
                        Save
                    </button>
                </div>
                <Error isPresented={showError} errorMessage={errorMessage} dismiss={(e) => { setShowError(false) }}></Error>
                <hr />
                <div id="pager" className="w-full">
                    <TablePagination data={photos} handleFilterChange={handleFilterChange} /> 
                </div>
                <div className="w-full flex">
                    <div className="grid grid-cols-1 lg:grid-cols-4 gap-3 px-4 lg:px-0 flex-col">
                        {photos.items.length === 0 ? ([1,2,3].map((i) => <div key={i} className="ring-1 ring-gray-200 overflow-hidden bg-white shadow-sm hover:shadow-lg">
                            <div className="">
                                <div className="relative  overflow-hidden group">
                                    <a href="#">
                                        <img className="w-full h-64 object-cover " />
                                    </a>    
                                </div>


                                <div className="px-4 pt-4 pb-2">
                                    <div className="flex items-center ">
                                        <div className="inline-flex items-center w-5/6 truncate overflow-ellipsis">
                                            <a href="#">
                                                <p className="font-semibold text-sm"></p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>))
                        :photos.items.map((photo) => <div key={photo.image_no} className="ring-1 ring-gray-200 overflow-hidden bg-white shadow-sm hover:shadow-lg">
                        <div className="">
                            <div className="relative  overflow-hidden group">
                                <a href={`${Constants.BASE_URL}${filters.type === "home"? `/homes/${photo.reference.title}/${photo.reference.id}`: `/p/${photo.reference.id}`}`} target="_blank" rel="noreferrer">
                                    <img className="w-full h-48 object-cover " src={photo.url} />
                                </a>
                                <div class="cursor-pointer absolute left-0 bottom-0 w-full p-4 opacity-100 bg-opacity-75 text-white text-xs overflow-y-auto">
                                    {(photo.labels_data || []).map((label) => (
                                        <small key={label.description} onClick={() => {
                                            let index = filters.labels.findIndex(item => item === label.description)
                                            if (index === -1) {
                                                props.setFilters({...filters, labels: [...filters.labels, label.description], page: 1})
                                            } 
                                        }} className="px-1 py-1 rounded text-xs bg-blue-600 text-white inline-block mr-1 mb-1 cursor-pointer">
                                            {label.description}
                                        </small>
                                    ))}
                                </div>
                            </div>
                
                
                            <div className="px-4 pt-4 pb-2">
                                <div className="flex items-center ">
                                    <div className="inline-flex items-center w-5/6 truncate overflow-ellipsis">
                                        <a href={`${Constants.BASE_URL}${filters.type === "home"? `/homes/${photo.reference.title}/${photo.reference.id}`: `/p/${photo.reference.id}`}`} target="_blank" rel="noreferrer">
                                            <p className="font-semibold text-sm">{photo.image_no}</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 pb-4">
                                <div className="text-xs font-semibold text-gray-500 flex gap-x-4 flex-wrap gap-y-2">
                                    <div className="inline-flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                        </svg>
                                        <a href={`${Constants.BASE_URL}${filters.type === "home"? `/homes/${photo.reference.title}/${photo.reference.id}`: `/p/${photo.reference.id}`}`} target="_blank" rel="noreferrer">
                                            {filters.type === "home"? <span className="ml-2">{photo.reference.id}{photo.reference.title}</span>: <span className="ml-2">{photo.reference.title}</span>}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}
                    </div>
                </div>
            </div>
        </div>
    </div>)
}
