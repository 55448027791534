import moment from 'moment'
import TopNavBar from '../Shared/TopNavBar'
import * as api from '../../services/admin'
import { Translate } from "../../translate/Translate";
import MapView from '../../components/MapView';
import { useEffect, useState, useMemo } from 'react';

export default function Lotnumber(props) {


    const [featureCollection, setFeatureCollection] = useState({
        "type": "FeatureCollection",
        "features": []
    })

    const [filterUser, setFilterUser] = useState(null)
    const [filterCity, setFilterCity] = useState(null)

    const filteredFeatures = useMemo(() => {
        let filteredFeatures = {...featureCollection}

        if (!filterUser && !filterCity) {
            return filteredFeatures
        }
        
        if (filterUser) {
            filteredFeatures.features = filteredFeatures.features.filter((f) => f.properties.user_email === filterUser)
        }
        if (filterCity) {
            filteredFeatures.features = filteredFeatures.features.filter((f) => f.properties['大字名'] === filterCity)
        }

        return filteredFeatures

    }, [featureCollection, filterUser, filterCity])

    const groupBy = useMemo(() => {
        let group = {
            users: {},
            city: {},
        }
        for (const feature of featureCollection.features) {
            group.users[feature.properties.user_email] = feature.properties.user_email
            if (filterUser) {
                if (feature.properties.user_email == filterUser) {
                    group.city[feature.properties['大字名']] = feature.properties['大字名']
                }
            } else {
                group.city[feature.properties['大字名']] = feature.properties['大字名']
            }
        }



        return {
            city_list: Object.keys(group.city),
            users_list: Object.keys(group.users),
            users_count: Object.keys(group.users).length,
            city_count: Object.keys(group.city).length,
        }
    }, [featureCollection, filterUser])

    function loadLotnumber() {
        api.lotnumbers()
            .then((res) => {
                const {data} = res
                setFeatureCollection((f) => ({...f,
                    features: data.map((item) => {
                        item.feature.properties.user_id = item.user_id
                        item.feature.properties.user_email = item.user_email
                        return {
                            ...item.feature,
                        }
                    })
                }))
            })
    }

    useEffect(() => {
        loadLotnumber()
    },[])


    return (<div className="absolute inset-0 w-full h-full flex flex-col">
        <div className='lg:flex items-center justify-between flex-wrap bg-white px-4 border-b w-full z-[1000] top-0'>
        </div>
        <main className="w-full h-full overflow-hidden flex flex-col relative">
            <div className="w-full py-3 flex items-center z-50 bg-white border-b">
            <TopNavBar active="lotnumber"></TopNavBar>

                <div className="flex items-center justify-center px-3">
                    <button id="side-panel-toggle-button" className="w-full relative">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path d="M5.625 3.75a2.625 2.625 0 100 5.25h12.75a2.625 2.625 0 000-5.25H5.625zM3.75 11.25a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75zM3 15.75a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75zM3.75 18.75a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75z"></path>
                        </svg>
                        <div data-field="total-selected" data-mobile="true" className="hidden absolute -top-2 -right-2 w-4 h-4 text-white rounded-full bg-blue-600 flex items-center justify-center font-medium text-xs text-center">
                        </div>
                    </button>
                </div>

                <div className="flex flex-col w-full relative">
                    <div className="w-full bg-white border rounded overflow-hidden flex items-center relative">
                        <div className="relative w-full">
                        <input placeholder="地番・住所検索" id="address-input" className="focus:outline-none focus:ring-0 border-0 border-none w-full border-0 appearance-none px-3 py-2" type="text" name="address" />
                        </div>
                    </div>
                    <div id="search-result-container" className="hidden z-50 mt-1 w-full absolute top-10 border rounded overflow-hidden">

                        <div id="search-result-loading-indicator" className="bg-white w-full p-3 flex items-center">
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                            </path>
                        </svg>
                        </div>
                        <div id="lot-number-search-result-container" className="hidden bg-white w-full flex flex-col">
                        </div>
                        <div id="suggested-addresses-container" className="hidden bg-white w-full flex flex-col">
                        </div>


                        <template id="suggested-land">
                        <div className="px-3 py-2 flex items-center border-b cursor-pointer lot-action gap-3 hover:bg-gray-100">
                            <div className="flex-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 text-gray-500">
                                    <path fillRule="evenodd" d="M8.157 2.175a1.5 1.5 0 00-1.147 0l-4.084 1.69A1.5 1.5 0 002 5.251v10.877a1.5 1.5 0 002.074 1.386l3.51-1.453 4.26 1.763a1.5 1.5 0 001.146 0l4.083-1.69A1.5 1.5 0 0018 14.748V3.873a1.5 1.5 0 00-2.073-1.386l-3.51 1.452-4.26-1.763zM7.58 5a.75.75 0 01.75.75v6.5a.75.75 0 01-1.5 0v-6.5A.75.75 0 017.58 5zm5.59 2.75a.75.75 0 00-1.5 0v6.5a.75.75 0 001.5 0v-6.5z" clipRule="evenodd"></path>
                                </svg>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-sm w-full lot-number font-semibold">番: 111-111</span>
                                <span className="text-sm w-full lot-number-area">市区町村名: 市区町村名</span>
                            </div>
                        </div>
                        </template>
                        <template id="suggested-address">
                        <div className="px-3 py-2  flex items-center border-b cursor-pointer gap-3 hover:bg-gray-100">
                            <div className="flex-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 text-gray-500">
                                    <path fillRule="evenodd" d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" clipRule="evenodd"></path>
                                </svg>
                            </div>
                            <p className="text-sm full-address w-full">Suggested address</p>
                        </div>
                        </template>

                        <template id="city-item">
                        <div className="w-full py-2 cursor-pointer border-b text-sm">Karuizawa</div>
                        </template>
                    </div>
                </div>
                <div className="px-3 ml-auto flex items-center gap-3">

                    <button id="share-button" className="rounded bg-blue-600 text-white font-medium px-3 py-2 flex items-center gap-1">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15"></path>
                        </svg>
                        <span className="hidden lg-block">シェア</span>
                    </button>
                    
                        
                        <button data-toggle-dialog="upgrade-dialog" className="hidden lg:block bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-semibold px-3 py-2 whitespace-nowrap rounded text-sm">
                        Proにアップグレード
                        </button>
                        
                    
                </div>
            </div>
            

            <div className="w-full h-full mt-auto flex items-start">
                <MapView history={props.history} featureCollection={filteredFeatures} />
            </div>

            <div id="map-options-container" className="slide-in-from-right absolute top-0 right-0 w-64 h-full bg-white pt-16">
                
                <div id="map-options-dialog" className="h-full flex flex-col bg-white divide-y text-sm overflow-hidden">

                    <div className=" flex flex-col bg-white">
                        <div className="pl-3 h-full p-2 font-semibold border-white flex items-center">
                            <h4>City ({groupBy.city_list.length})</h4>
                            <div className='ml-auto'>
                                <button type='button' className='text-sm' onClick={() => {
                                    setFilterCity(null)
                                }}>
                                deselected
                                </button>
                            </div>
                        </div>
                        
                    </div>
                    <div className="flex flex-col overflow-ellipsis truncate gap-2 p-2">
                        {groupBy.city_list.map((city) => (<div key={city} className={`text-xs font-medium cursor-pointer  text-gray-400 overflow-ellipsis truncate hover:bg-blue-400 ${filterCity===city ? "bg-blue-600":""}`} onClick={() => { setFilterCity(city) }} >{city}</div>))}
                    </div>         

                </div>
                
            </div>

            <button type="button" className="absolute bottom-4 right-4 rounded-full bg-white w-12 h-12 drop-shadow flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"></path>
                </svg>       
            </button>

            
            
            <div id="side-panel" className="slide-out bg-white pt-16 absolute top-0 left-0 flex w-64 h-full z-40 drop-shadow">

                <div className="flex flex-col w-full">
                    
                    <div className="border-t flex items-center pt-3 px-3 w-full">
                        <div className="items-center overflow-ellipsis truncate w-full flex-col">
                            <h4 className='flex mb-4'>
                                <span>Users ({groupBy.users_list.length})</span>
                                <div className='ml-auto'>
                                    <button type='button' className='text-sm' onClick={() => {
                                        setFilterUser(null)
                                    }}>
                                    deselected
                                    </button>
                                </div>
                            </h4>
                            <div className="flex flex-col overflow-ellipsis truncate gap-2">
                                {groupBy.users_list.map((email) => (<div key={email} className={`text-xs font-medium cursor-pointer text-gray-400 overflow-ellipsis truncate hover:bg-blue-400 ${filterUser===email ? "bg-blue-600":""}`} onClick={() => { setFilterUser(email) }} >{email}</div>))}
                            </div>         
                        </div>
                        
                        </div>
                </div>
                
                <div className="absolute top-0 -right-8 w-8 h-full z-50 bg-white border-l border-r flex items-center justify-center">
                    <button id="side-panel-dismiss-button" className="w-full h-full flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-3 h-3">
                        <path fillRule="evenodd" d="M13.28 3.97a.75.75 0 010 1.06L6.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5a.75.75 0 010-1.06l7.5-7.5a.75.75 0 011.06 0zm6 0a.75.75 0 010 1.06L12.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5a.75.75 0 010-1.06l7.5-7.5a.75.75 0 011.06 0z" clipRule="evenodd"></path>
                        </svg>
                    </button>
                </div>
            </div>
        
        </main>
    </div>)
}