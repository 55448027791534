import React from "react"
import * as Constants from '../../constants'


export function PhotoRow({data, filters, setFilters}) {
    return (<div key={data.image_no} className="ring-1 ring-gray-200 overflow-hidden bg-white shadow-sm hover:shadow-lg">
        <div className="">
            <div className="relative  overflow-hidden group">
                <a href={`${Constants.BASE_URL}${filters.type === "home"? `/homes/${data.reference.title}/${data.reference.id}`: `/p/${data.reference.id}`}`} target="_blank" rel="noreferrer">
                    <img className="w-full h-64 object-cover " src={data.url} />
                </a>
            </div>


            <div className="px-4 pt-4 pb-2">
                <div className="flex items-center ">
                    <div className="inline-flex items-center w-5/6 truncate overflow-ellipsis">
                        <a href={`${Constants.BASE_URL}${filters.type === "home"? `/homes/${data.reference.title}/${data.reference.id}`: `/p/${data.reference.id}`}`} target="_blank" rel="noreferrer">
                            <p className="font-semibold text-sm">{data.image_no}</p>
                        </a>
                    </div>
                </div>
            </div>
            <div className="px-4 pb-4">
                <div className="text-xs font-semibold text-gray-500 flex gap-x-4 flex-wrap gap-y-2">
                    <div className="inline-flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                        </svg>
                        <a href={`${Constants.BASE_URL}${filters.type === "home"? `/homes/${data.reference.title}/${data.reference.id}`: `/p/${data.reference.id}`}`} target="_blank" rel="noreferrer">
                            {filters.type === "home"? <span className="ml-2">{data.reference.id}{data.reference.title}</span>: <span className="ml-2">{data.reference.title}</span>}
                        </a>
                    </div>
                    <br />
                    <div className="inline-flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                        </svg>
                    </div>
                    <div className="inline-flex items-center">
                        <span className="ml-4">
                        {(data.labels_data || []).map((label) => (
                            <small key={label.description} onClick={() => {
                                let index = filters.labels.findIndex(item => item === label.description)
                                if (index === -1) {
                                    setFilters({...filters, labels: [...filters.labels, label.description]})
                                } 
                            }} className="px-1 py-1 rounded text-xs bg-blue-600 text-white inline-block mr-1 mb-1 cursor-pointer">
                                {label.description} ({(label.score*100).toFixed()}%)
                            </small>
                        ))}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}