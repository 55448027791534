import React, {useEffect} from "react"
import * as api from '../../services/auth'
import * as Account from '../../account';
import { Translate } from "../../translate/Translate";
import { GOOGLE_OAUTH2_CLIENT_ID } from "../../constants";

export default function Login(props) {
    let auth;
    
    useEffect(() => {
        if (window.gapi?.auth2) {
            window.gapi.auth2.init({
                client_id: GOOGLE_OAUTH2_CLIENT_ID,
                scope: "profile email",
                redirect_uri: window.location.origin,
            }).then(() => {
                console.log("client initial completed", window.gapi.auth2.getAuthInstance())
                auth = window.gapi.auth2.getAuthInstance()
                
            })
        }
    })
    
    
    const [error, setError] = React.useState(null)
 

    const handleSignIn = async (e) => {
        e.preventDefault();
        try {
            if (!auth) {
                await window.gapi.auth2.init({
                    client_id: GOOGLE_OAUTH2_CLIENT_ID,
                    scope: "profile email",
                    redirect_uri: window.location.origin,
                })
                auth = window.gapi.auth2.getAuthInstance()
            }
            const gLoginResponse = await auth.signIn()
            const authenResponse = await api.signIn({token: gLoginResponse.getAuthResponse().id_token})
            if (authenResponse.user.is_viila_admin) {
                Account.updateAccount(authenResponse)
            }
            window.location.href = "/"
        } catch (error) {
            console.log("error", error)
            setError(error)
            setTimeout(() => {
                setError(null)
            }, 3000);
        }
    }

    const renderError = () => {
        if (error === null) {
            return "";
        }
        return (
            <div className="bg-red-100 text-red-500 mt-6 rounded py-2 text-center">
                {error.message}
            </div>
        )
    }
    return (
        <div className="">
            <div id="g_id_onload"
                data-client_id="379613756082-57r2c458vvjajvgmr8ieqmbhrrs7059j.apps.googleusercontent.com"
                data-callback="oneTapSignInCallBack">
            </div>
            <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div>
                        <div className="flex items-center justify-center">
                            <div className="mx-auto inline-flex justify-center items-center ">
                                <svg className="fill-current text-indigo-600 w-20 h-20" viewBox="0 0 32 30"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0 15.5471C0 16.2122 0.523303 16.7877 1.36059 16.7877C1.76615 16.7877 2.13246 16.5703 2.45953 16.3145L3.95094 15.0867V26.9815C3.95094 28.8745 5.11529 30 7.11693 30H24.8177C26.8062 30 27.9836 28.8745 27.9836 26.9815V15.0227L29.5536 16.3145C29.8675 16.5703 30.2339 16.7877 30.6394 16.7877C31.4113 16.7877 32 16.3145 32 15.5727C32 15.1378 31.8299 14.7925 31.4898 14.5111L27.9836 11.6205V6.1719C27.9836 5.59634 27.6043 5.23822 27.0155 5.23822H25.2101C24.6345 5.23822 24.242 5.59634 24.242 6.1719V8.55087L17.8708 3.31969C16.7326 2.38601 15.2935 2.38601 14.1554 3.31969L0.523303 14.5111C0.170074 14.7925 0 15.1762 0 15.5471ZM7.8103 27.4548C6.99918 27.4548 6.55437 27.0071 6.55437 26.2013V12.9507L15.4374 5.66029C15.8038 5.35333 16.2224 5.35333 16.5887 5.66029L25.3671 12.874V26.2013C25.3671 27.0071 24.9223 27.4548 24.1112 27.4548H7.8103Z" />
                                    <path
                                        d="M28.1328 1.96457C28.1328 3.04957 27.2331 3.92914 26.1233 3.92914C25.0135 3.92914 24.1138 3.04957 24.1138 1.96457C24.1138 0.879569 25.0135 0 26.1233 0C27.2331 0 28.1328 0.879569 28.1328 1.96457Z" />
                                </svg>
                            </div>
                        </div>
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                            {Translate("Sign in to your account")}
                         </h2>
                    </div>
                    <div className="mt-8 space-y-4  bg-white rounded-lg p-4 lg:p-8 shadow">
                        <div>
                            <button type="button" id="google-signin" className="text-sm bg-white px-2 focus:outline-none w-full inline-flex items-center border rounded-md py-2 justify-center shadow" 
                                onClick={handleSignIn}>
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" className="abcRioButtonSvg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
                                <span className="ml-2 font-medium ">{Translate("sign_in_with_google")}</span>
                            </button>
                        </div>
                    </div>
                    {renderError()}
                </div>
            </div>
        </div>
    );
}