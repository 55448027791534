
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import React from "react";

import * as Account from "./account";
import * as api from './services/admin'

import Home from './views/Home';
import Photo from './views/Photo';
import LabelGroup from './views/LabelGroup';
import Interest from './views/Interest';
import HomeExample from './views/HomeExample';
import LotNumber from './views/LotNumber';
import Company from './views/Company';
import NewCompany from './views/Company/NewCompany';
import CompanyDetail from './views/Company/CompanyDetail';
import SellRequest from './views/SellRequest';
import SellRequestDetail from './views/SellRequest/SellRequestDetail';
import NewHomeExample from './views/HomeExample/NewHomeExample';
import NewHomeExampleSteps from './views/HomeExample/NewHomeExampleSteps';
import HomeExampleDetail from './views/HomeExample/HomeExampleDetail';
import Login from "./views/Login";
import { TranslateWithLang } from "./translate/Translate";
import LangContext from "./LangContext";

function App() {

  const query = new URLSearchParams(window.location.search);
  const [homes, setHomes] = React.useState({ items: [] });
  const [keywords, setKeywords] = React.useState("");
  const [allAreas, setAllAreas] = React.useState([]);
  const [areas, setAreas] = React.useState([]);

  const [filters, setFilters] = React.useState(JSON.parse(query.get("query") ?? '{}'));
  const [featureList, setFeatureList] = React.useState([]);
  const [defaultFeatures, setDefaultFeatures] = React.useState({});
  const [businessCompanies, setBusinessCompanies] = React.useState([]);
  const [tags, setTags] = React.useState([]);

  const [account] = React.useState(Account.getUserAccount());

  const [language, setLanguage] = React.useState(account?.user.settings?.language ? account?.user.settings?.language: "ja");
  const langValue = { language, setLanguage };

  const handleFilterChange = (e) => {
    const { name, value } = e.target
    setFilters({ ...filters, [name]: value })
  }

  const handleFilterKeyword = (e) => {
    setKeywords(e.target.value.trim())
  }

  const search = (e) => {
    setFilters({ ...filters, keywords: keywords })
  }

  async function loadAreas(lang) {
    try {
      const response = await api.areas()
      for (let i = 0; i < response.data.length; i++) {
        response.data[i].name = TranslateWithLang(lang, response.data[i].name)
      }
      setAreas(response.data);
    } catch (error) {
      console.log(error);
    }
  }
  
  async function loadAllAreas(lang) {
    try {
      const response = await api.allAreas()
      for (let i = 0; i < response.data.length; i++) {
        response.data[i].name = TranslateWithLang(lang, response.data[i].name)
      }
      setAllAreas(response.data);
    } catch (error) {
      console.log(error);
    }
  }
  async function loadFeatures(lang) {
    try {
      const response = await api.features(lang)
        setFeatureList(response.data);
    } catch (error) {
        console.log(error);
    }
  }
  async function loadDefaultFeatures() {
    try {
      const response = await api.defaultFeatures()
      setDefaultFeatures(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function loadHomes(query) {
    try {
      const data = await api.fetchHomes(query)
      setHomes(data);  
    } catch (error) {
      console.log(error);
    }
  }

  async function loadBusinessCompanies() {
    try {
      const data = await api.businessCompanies(query)
      setBusinessCompanies(data);  
    } catch (error) {
      console.log(error);
    }
  }

  async function loadTags() {
    try {
      const response = await api.tags()
      setTags(response.data);  
    } catch (error) {
      console.log(error);
    }
  }
  

  React.useEffect(() => {
    if (account === null) {
      return;
    }
    loadHomes(filters);
  }, [filters, account]);

  React.useEffect(() => {
    if (account === null) {
      return;
    }
    loadAreas(language);
    loadAllAreas(language);
    loadFeatures(language);
    loadBusinessCompanies();
    loadDefaultFeatures()
    loadTags()
  }, [account]);

  React.useEffect(() => {
    if (account === null) {
      return;
    }
    loadAreas(language);
    loadAllAreas(language);
    loadFeatures(language);
  }, [language]);


  if (account === null) {
    return (
      <LangContext.Provider value={langValue}>
        <Router>
          <Switch>
            <Route path="/"
              render={(props) => (
                <Login {...props} />
              )}
            />
          </Switch>
        </Router>
      </LangContext.Provider>
    );
  }

  return (
  <LangContext.Provider value={langValue}>
    <Router>
      <Switch>
        <Route path="/company/new"
          render={(props) => (
            <NewCompany {...props} filters={filters} keywords={keywords} account={account} businessCompanies={businessCompanies} allAreas={allAreas} handleFilterChange={handleFilterChange} handleFilterKeyword={handleFilterKeyword} search={search} />
          )}
        />
        <Route path="/company/:id"
          render={(props) => (
            <CompanyDetail {...props} filters={filters} keywords={keywords} account={account} businessCompanies={businessCompanies} allAreas={allAreas} handleFilterChange={handleFilterChange} handleFilterKeyword={handleFilterKeyword} search={search} />
          )}
        />
        <Route path="/company"
          render={(props) => (
            <Company {...props} filters={filters} keywords={keywords} account={account} businessCompanies={businessCompanies} handleFilterChange={handleFilterChange} handleFilterKeyword={handleFilterKeyword} search={search} />
          )}
        />
        <Route path="/example/steps"
            render={(props) => (
              <NewHomeExampleSteps {...props} language={language} areas={areas} />
            )}
          />
        <Route path="/example/new"
            render={(props) => (
              <NewHomeExample {...props} featureList={featureList} businessCompanies={businessCompanies} language={language} defaultFeatures={defaultFeatures} tags={tags} />
            )}
          />
        <Route path="/example/:id"
            render={(props) => (
              <HomeExampleDetail {...props} language={language} featureList={featureList} businessCompanies={businessCompanies} areas={areas} tags={tags}/>
            )}
          />
        <Route path="/example"
          render={(props) => (
            <HomeExample {...props} filters={filters} keywords={keywords} account={account} homes={homes} handleFilterChange={handleFilterChange} handleFilterKeyword={handleFilterKeyword} search={search} />
          )}
        />
        <Route path="/interest"
          render={(props) => (
            <Interest {...props} account={account} />
          )}
        />
        <Route path="/photo"
          render={(props) => (
            <Photo {...props} account={account} />
          )}
        />
        <Route path="/label"
          render={(props) => (
            <LabelGroup {...props} account={account} />
          )}
        />
        <Route path="/sellrequests/:id"
          render={(props) => (
            <SellRequestDetail {...props} account={account} />
          )}
        />
        <Route path="/sellrequests"
          render={(props) => (
            <SellRequest {...props} account={account} />
          )}
        />
        <Route path="/lotnumber"
          render={(props) => (
            <LotNumber {...props} account={account} />
          )}
        />
        <Route path="/"
          render={(props) => (
            <Home {...props} account={account} />
          )}
        />
        
      </Switch>
    </Router>
  </LangContext.Provider>
  );
}

export default App;
