import React, {useState,useEffect} from "react"
import Select from 'react-select'
import * as api from '../../services/admin'
import { Link } from 'react-router-dom';
import {PhotoRow} from './PhotoRow'
import TopNavBar from '../Shared/TopNavBar'
import TablePagination from '../Shared/TablePagination'
import { Translate } from "../../translate/Translate";

const selectStyles = {
    control: base => ({
        ...base,
        border: 0,
        // This line disable the blue border
        boxShadow: 'none'
    })
};

export default function Listing(props) {
    const query = new URLSearchParams(window.location.search);

    const photoTypes = ["home","property"].map(value => ({
        name: value,
        id: value,
    }))
    const [tag, setTag] = useState({})
    const [tags, setTags] = useState([])
    const [filterKeyword, setFilterKeyword] = useState('')
    const [labels, setLabels] = useState([])
    const [photos, setPhotos] = useState({
        items:[], 
        total_item: 0,
        total_page: 0,
        current_page: 0,
        page_size: 0,
        from: 0,
        to: 0,
    })
    const [filters, setFilters] = useState(JSON.parse(query.get("query") ?? `{
        "page": 1,
        "labels": [],
        "page_size": 100,
        "type":"home",
        "mode":"label",
        "tag":""
    }`))


    function loadPhotoLabels(type) {
        api.fetchPhotoLabels(type)
            .then((response) => {
                setLabels(response.sort((x,y) => y.count - x.count))
            })
    }

    function loadPhotos(selected) {
        api.fetchHomePhotos(selected)
            .then((response) => {
                console.log("loadPhotos", selected)
                setPhotos({...response, items: [...response.items]})
            })
    }

    function loadTags() {
        return api.fetchTagLabel()
            .then((response) => {
                setTags(response.data.map((value) => ({
                    name: value.name,
                    id: value.name,
                    ...value
                })))

                return Promise.resolve()
            })
    }

    function handleFilterChange({target}) {
        // { target: { name: "page", value: photos.current_page - 1 } }
        const { name, value } = target
        setFilters({ ...filters, [name]: value })
    }

    useEffect(() => {
        loadPhotoLabels("home")
        loadPhotos(filters)
        loadTags()
    }, [])

    useEffect(() => {
        if (filters.tag !== "") {
            const tag = tags.find((tag) => tag.name === filters.tag)
            setTag(tag || {})
        }
    }, [tags])

    useEffect(() => {
        loadPhotos(filters)
        props.history.replace({
            pathname: window.location.pathname,
            search: `?query=${JSON.stringify(filters)}`
        })
    }, [filters.page, filters.labels, filters.tag, filters.mode])

    useEffect(() => {
        loadPhotoLabels(filters.type)
        loadPhotos(filters)
        props.history.replace({
            pathname: window.location.pathname,
            search: `?query=${JSON.stringify(filters)}`
        })
    }, [filters.type])

    return (
        <div className="">
            <TopNavBar active="photo"></TopNavBar>
            <div className="mt-16 w-full container mx-auto py-8 px-4">
                <div className="mb-8 flex justify-between items-center">
                    <div>
                        <p className="text-2xl font-semibold">{Translate("Listing")}</p>
                    </div>
                    <div className="relative inline-flex rounded-md shadow-sm -space-x-px">
                        <Select
                            styles={selectStyles}
                            className="text-base border border-gray-600 rounded "
                            id="photoTypes"
                            name="photoTypes"
                            options={photoTypes}
                            getOptionLabel={({ name }) => name}
                            getOptionValue={({ id }) => id}
                            onChange={(value) => {
                                setFilters({...filters, type: value.id})
                            }}
                            value={photoTypes.filter(t => t.id === filters.type)}
                        />
                    </div>
                </div>
                {filters.mode === "label" ? (<div>
                    <div id="filter-section" className="w-full mb-8">
                        {filters.labels.map((select) => (
                            <p key={select} className="px-3 py-2 rounded text-sm bg-blue-600 text-white inline-block mr-1 mb-1 cursor-default">
                                {select} 
                                <span className="my-auto ml-2 inline-flex cursor-pointer" onClick={(e) => { 
                                    let index = filters.labels.findIndex(item => item === select)
                                    if (index !== -1) {
                                        filters.labels.splice(index, 1)
                                        setFilters({...filters, labels: [...filters.labels], page: 1})
                                    }
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                </span>
                            </p>
                        ))}
                    </div>
                    {filters.labels.length === 0 ? null: (<div class="inline-flex mb-4">
                        <span class="mr-4">{Translate("Tag")}</span>
                        <Select
                            styles={selectStyles}
                            className="text-base border border-gray-600 rounded mr-4"
                            id="tags"
                            name="tags"
                            options={tags}
                            getOptionLabel={({ name }) => name}
                            getOptionValue={({ id }) => id}
                            onChange={(value) => {
                                setFilters({...filters, tag: value.id})
                            }}
                            value={tags.filter(t => t.id === filters.tag)}
                        />
                        <Link to={`/label?query={"labels": ${JSON.stringify(filters.labels)},"tag": ${JSON.stringify(filters.tag)},"photo_forward":true}`} onClick={(e) => {
                            if(!filters.tag) {
                                e.preventDefault()
                            }
                        }} className={`btn btn-primary inline-flex items-center ${!filters.tag? "bg-gray-300 hover:bg-gray-400": ""}`}>
                            <svg className="w-4 h-4 mr-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 13.8594C7.28906 13.8594 7.53125 13.625 7.53125 13.3359V7.90625H12.8047C13.0859 7.90625 13.3281 7.66406 13.3281 7.375C13.3281 7.08594 13.0859 6.84375 12.8047 6.84375H7.53125V1.40625C7.53125 1.11719 7.28906 0.882812 7 0.882812C6.71094 0.882812 6.46875 1.11719 6.46875 1.40625V6.84375H1.20312C0.914062 6.84375 0.671875 7.08594 0.671875 7.375C0.671875 7.66406 0.914062 7.90625 1.20312 7.90625H6.46875V13.3359C6.46875 13.625 6.71094 13.8594 7 13.8594Z" fill="white" />
                            </svg>
                            <span>{Translate("Add Label Group")}</span>
                        </Link>
                    </div>)}
                </div>): null}
                {filters.mode === "tag" && filters.tag ? (<div>
                    <div>
                        <span class="mr-4">{Translate("Tags")}</span>    
                        <p className={`px-3 py-2 rounded text-sm bg-green-600 text-white inline-block mr-1 mb-1 cursor-pointer`}>{filters.tag}</p>
                    </div>
                    <div>
                        <span class="mr-4">{Translate("Label Group")}</span>    
                        {(tag.label_group || []).map((items, index) => <p key={index} onClick={() => {
                        if (items.join("+") !== filters.labels.join("+")) {
                            setFilters({...filters, labels: items, mode: "tag", page: 1})
                        } else {
                            setFilters({...filters, labels: [], mode: "tag", page: 1})
                        }
                    }} className={`px-3 py-2 rounded text-sm ${items.join("+") === filters.labels.join("+") ? "bg-green-500": "bg-blue-600"}  text-white inline-block mr-1 mb-1 cursor-pointer`}>{items.join("+")}</p>)}
                    </div>
                    
                </div>): null}
                
                <div id="pager" className="w-full">
                    <TablePagination data={photos} handleFilterChange={handleFilterChange} /> 
                </div>
                <div className="w-full flex">
                    <div className="grid grid-cols-1 lg:grid-cols-4 gap-3 px-4 lg:px-0 flex-col h-1/4 w-4/5">
                        {photos.items.length === 0 ? ([1,2,3].map((i) => <div key={i} className="ring-1 ring-gray-200 overflow-hidden bg-white shadow-sm hover:shadow-lg">
                            <div className="">
                                <div className="relative  overflow-hidden group">
                                    <a href="#">
                                        <img className="w-full h-64 object-cover " />
                                    </a>    
                                </div>


                                <div className="px-4 pt-4 pb-2">
                                    <div className="flex items-center ">
                                        <div className="inline-flex items-center w-5/6 truncate overflow-ellipsis">
                                            <a href="#">
                                                <p className="font-semibold text-sm"></p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>))
                        :null}
                        {photos.items.map((photo) => <PhotoRow key={photo.image_no} data={photo} filters={filters} setFilters={setFilters} />)}
                    </div>
                    
                    <div className="w-1/3 ml-4 flex-col h-screen">
                        <ul class="flex justify-center items-center my-4">
                            <li class={`cursor-pointer py-2 px-4 text-gray-500 border-b-8 ${filters.mode === "label" ? "text-green-500 border-green-500":""}`} onClick={() => {

                                setFilters({...filters, tag: undefined, mode: "label", page: 1})
                                }}>Search Label</li>
                            <li class={`cursor-pointer py-2 px-4 text-gray-500 border-b-8 ${filters.mode === "tag" ? "text-green-500 border-green-500":""}`} onClick={() => {
                                
                                setFilters({...filters, labels: [], mode: "tag", page: 1})
                            }} >Search Tag</li>
                        </ul>
                        {filters.mode === "label" ? <div>
                            <div class="w-full inline-flex items-center mb-2">
                                <p className="text-2xl font-semibold">{Translate("Labels")}</p>
                                <input type="text" placeholder="filter keyword" class="rounded w-full ml-2" value={filterKeyword} onChange={(e) => {
                                    setFilterKeyword(e.target.value)
                                }} />
                            </div>
                            {labels.filter(({description}) => description.toLowerCase().includes(filterKeyword))
                            .map((label) => <p key={label.description} onClick={() => {
                                let index = filters.labels.findIndex(item => item === label.description)
                                if (index === -1) {
                                    setFilters({...filters, labels: [...filters.labels, label.description], page: 1})
                                } else {
                                    filters.labels.splice(index, 1)
                                    setFilters({...filters, labels: [...filters.labels], page: 1})
                                }
                            }} className="px-3 py-2 rounded text-sm bg-blue-600 text-white inline-block mr-1 mb-1 cursor-pointer">{label.description} ({label.count})</p>)}
                        </div>: null}
                        {filters.mode === "tag" ? <div>
                            <div class="w-full inline-flex items-center mb-2">
                                <p className="text-2xl font-semibold">{Translate("Tags")}</p>
                                <input type="text" placeholder="filter keyword" class="rounded w-full ml-2" value={filterKeyword} onChange={(e) => {
                                    setFilterKeyword(e.target.value)
                                }} />
                            </div>
                            {tags.map(({name, label_group}) => <p key={name} onClick={() => {
                                console.log("label_group", label_group)
                                if (filters.tag !== name) {
                                    setFilters({...filters, tag: name, page: 1})
                                    setTag({name, label_group})
                                } else {
                                    setFilters({...filters, tag: undefined, page: 1})
                                    setTag({})
                                }
                            }} className="px-3 py-2 rounded text-sm bg-green-600 text-white inline-block mr-1 mb-1 cursor-pointer">{name}</p>)}
                        </div>: null}
                        
                    </div>
                    
                </div>
            </div>
        </div>
    )
}