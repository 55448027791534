import {_authorizedRequest} from '../http'

export async function fetchHomes(query) {
    var url = "/homes"
    if (query !== null) {
        url = `/homes?query=${JSON.stringify(query)}`
    }
    return _authorizedRequest("GET", url, null)
}

export async function fetchHomeDetail(id) {
    return _authorizedRequest("GET", `/homes/${id}`, null)
}

export async function fetchTags() {
    return _authorizedRequest("GET", `/tags`, null)
}

export async function fetchTagLabel() {
    return _authorizedRequest("GET", `/tags/group`, null)
}

export async function createTagLabel(body) {
    return _authorizedRequest("POST", `/tags/group`, body)
}

export async function fetchPhotoLabels(type) {
    return _authorizedRequest("GET", `/homes/photos/labels?type=${type}`, null)
}

export async function fetchHomePhotos(query) {
    return _authorizedRequest("GET", `/homes/photos?query=${JSON.stringify(query)}`, null)
}

export async function fetchProperties(query) {
    return _authorizedRequest("GET", `/p?query=${JSON.stringify(query)}`, null)
}

export async function fetchCompany(id) {
    return _authorizedRequest("GET", `/companies/${id}`, null)
}

export async function uploadImage(base64Image) {
    let data = {
        imageBase64: base64Image
    }
    return _authorizedRequest("POST", `/file/upload`, data)
}

export async function createNewHomeExample(data) {
    return _authorizedRequest("POST", `/homes`, data)
}

export async function createNewCompany(data) {
    return _authorizedRequest("POST", `/companies`, data)
}
export async function updateCompany(data) {
    return _authorizedRequest("POST", `/companies/${data.id}`, data)
}

export async function updateExample(data) {
    return _authorizedRequest("POST", `/homes/${data.id}`, data)
}

export async function deleteExample(data) {
    return _authorizedRequest("DELETE", `/homes`, data)
}

export async function areas() {
    return _authorizedRequest("GET", `/areas`, null)
}

export async function allAreas() {
    return _authorizedRequest("GET", `/areas/all`, null)
}

export async function features(lang = "en") {
    return _authorizedRequest("GET", `/${lang}/features`, null)
}

export async function businessCompanies() {
    return _authorizedRequest("GET", `/companies`, null)
}

export async function defaultFeatures() {
    return _authorizedRequest("GET", `/features/default`, null)
}

export async function tags() {
    return _authorizedRequest("GET", `/tags`, null)
}

export async function interest() {
    return _authorizedRequest("GET", `/interest`, null)
}

export async function listSellrequests() {
    return _authorizedRequest("GET", `/v2/approve`, null)
}

export async function fetchSellrequest(id) {
    return _authorizedRequest("GET", `/v2/approve/${id}`, null)
}
export async function approveSellrequest(id) {
    return _authorizedRequest("PUT", `/v2/approve/${id}`, null)
}

export async function allAgents() {
    return _authorizedRequest("GET", `/v2/dashboard/agents`, null)
}

export async function generateLoginCode(agentID) {
    return _authorizedRequest("GET", `/v2/account/login/${agentID}/code`, null)
}

export async function lotnumbers() {
    return _authorizedRequest("GET", `/v2/dashboard/lotnumbers`, null)
}