import React, { useEffect,useState } from "react"
import TopNavBar from '../Shared/TopNavBar'
import { Translate } from "../../translate/Translate";
import { AGENT_URL, PROPEZ_URL } from "../../constants";
import { allAgents, generateLoginCode } from "../../services/admin";

export default function Home(props) {
    const [agents, setAgents] = useState([])
    const [selected, setSelected] = useState('')
    const [code, setCode] = useState(null)

    function loadAgents() {
        allAgents()
            .then((res) => {
                setAgents(res.data)
            })
    }

    useEffect(() => {
        loadAgents()
    }, [])

    function generateCode() {
        generateLoginCode(selected)
            .then((c) => {
                console.log("c", c)
                setCode(c)
            })
    }
    
    return (
        <div className="">
            <TopNavBar active="dashboard"></TopNavBar>
            <div className="mt-16 mb-16 w-full container mx-auto py-8 px-4">
                <div className="mb-8 flex justify-between items-center">
                    <div>
                        <p className="text-2xl font-semibold">{Translate("Dashboard")}</p>
                    </div>
                   
                </div>
                <main className="w-full grid grid-cols-2">
                    <div className="p-4 border">
                        <h3 className="text-xl font-bold">Generate Login code</h3>
                        <div className="flex gap-4">
                            <select className="w-full" value={selected} onChange={(e) => {
                                setSelected(e.target.value)
                            }}>
                                <option value="">please select</option>
                                {agents.map((a) => <option key={a.agent_id} value={a.agent_id}>{a.email} ({a.agent_id})</option>)}
                            </select>
                            <button className="bg-gray-600 text-white p-2 rounded ml-auto m-2" onClick={generateCode}>Generate</button>
                        </div>
                        {code && <div className="mt-4 grid grid-cols-2">
                            <a href={`${AGENT_URL}/?code=${code}`} target="_blank">{AGENT_URL}</a>
                            <a href={`${PROPEZ_URL}/auto/login/${code}`} target="_blank">{PROPEZ_URL}</a>
                        </div>}
                    </div>
                </main>
            </div>
        </div>
    )
}