import React from "react"
import * as Constants from '../../constants'

function slugParse(name) {
    let slug = name.trim().toLowerCase()
    slug = slug.replaceAll(/[^a-zA-Z0-9-_一-龯ぁ-んァ-ン０-９]/g, "-")
    slug = slug.replaceAll(/-+/g, "-")
    return slug
}

export const PropertyItem = (props) => {
    const {property} = props
    return (<div>
        <div data-property={property.property_id} id={property.property_id} class="rounded-lg">
            <div class="w-full bg-white lg:border shadow-sm hover:shadow-lg overflow-hidden relative  transition duration-500 ease-in-out transform">
                <div class="flex items-center p-4">
                    <a class="inline-flex items-center" href={`${Constants.BASE_URL}/agents/${property.listed_by.agent_id}/${slugParse(property.listed_by.company.name)}`} rel="canonical">
                        <div class="bg-gray-300 rounded-full h-8 w-8 overflow-hidden border">
                            <img src={property.listed_by.company.image_url} class="w-full h-full object-cover rounded-full" />
                        </div>
                        <div class="text-md ml-2 inline-flex items-center">
                            <p class=" text-sm font-semibold leading-tight">{property.listed_by.company.name}</p>
                        </div>
                    </a>
                    <div class="ml-auto">
                    </div>
                </div>
                <div class="w-full flex-none relative">
                    <a href={`${Constants.BASE_URL}/${property.area.id.toLowerCase()}/${slugParse(property.name)}/${property.property_id}`} rel="canonical"> 
                        <div class="absolute p-2 z-20">
                            <div class="flex flex-wrap">
                                <div class="mr-2 mb-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs uppercase focus:outline-none bg-black bg-opacity-50">
                                    <svg class="fill-current text-white w-3 h-3" viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.44531 13.9531H4.89062V15.2969C4.89062 16.9062 5.71094 17.7188 7.34375 17.7188H19.1953C20.8203 17.7188 21.6484 16.9062 21.6484 15.2969V6.99219C21.6484 5.39062 20.8203 4.57812 19.1953 4.57812H17.7422V3.3125C17.7422 1.70312 16.9219 0.890625 15.2891 0.890625H3.44531C1.80469 0.890625 0.992188 1.70312 0.992188 3.3125V11.5391C0.992188 13.1484 1.80469 13.9531 3.44531 13.9531ZM3.46094 12.6953C2.67969 12.6953 2.25 12.2812 2.25 11.4688V3.38281C2.25 2.57031 2.67969 2.14844 3.46094 2.14844H15.2734C16.0391 2.14844 16.4844 2.57031 16.4844 3.38281V4.57812H7.34375C5.71094 4.57812 4.89062 5.38281 4.89062 6.99219V12.6953H3.46094ZM6.14844 7.0625C6.14844 6.25 6.58594 5.83594 7.36719 5.83594H19.1719C19.9453 5.83594 20.3906 6.25 20.3906 7.0625V13.5859L17.5234 10.8828C17.1797 10.5625 16.7734 10.4062 16.3438 10.4062C15.9141 10.4062 15.5391 10.5547 15.1719 10.875L11.6641 13.9844L10.2656 12.7188C9.92969 12.4297 9.57812 12.2734 9.20312 12.2734C8.85938 12.2734 8.53125 12.4141 8.20312 12.7109L6.14844 14.4922V7.0625ZM10.3906 11.0781C11.3984 11.0781 12.2266 10.25 12.2266 9.22656C12.2266 8.22656 11.3984 7.38281 10.3906 7.38281C9.375 7.38281 8.54688 8.22656 8.54688 9.22656C8.54688 10.25 9.375 11.0781 10.3906 11.0781Z"></path>
                                    </svg>
                                    <span class="ml-1 font-semibold text-white text-xs">{property.images.length}</span>
                                </div>
                            </div>
                        </div>
                        {property.images.length === 0 ? (<div class="z-0 h-64 w-full object-cover bg-gray-100 relative"></div>):(<div class="w-full relative h-64 z-10">
                            <img id="main-image" decoding="async" class={`absolute top-0 left-0  z-0 w-full h-64 object-cover ls-is-cached lazyloaded ${property.images.length > 1? "hover:opacity-0": ""}`} src={property.images[0].url} />
                            {property.images.length > 1? <img decoding="async" class="z-0 w-full h-64 object-cover ls-is-cached lazyloaded" src={property.images[1].url} />: null}
                        </div>)}
                    </a>
                </div>
                <div class="w-full p-4">
                    <a href={`${Constants.BASE_URL}/${property.area.id.toLowerCase()}/${slugParse(property.name)}/${property.property_id}`} rel="canonical">
                        <div class=" ">
                            <div class="flex items-center mb-1">
                                <p class="capitalize text-xs font-medium text-gray-600">{property.area.name}&nbsp;·&nbsp;{property.property_type}&nbsp;·&nbsp;{property.property_id}&nbsp;·&nbsp;</p>
                                <div class="inline-flex items-center text-gray-600" title="ページビュー数">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 mr-1 fill-current" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                                        <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd"></path>
                                    </svg>
                                    <span class="font-medium text-xs">{property.page_view}</span>
                                </div>
                            </div>
                            <p class="font-medium lg:truncate">{property.name}</p>
                            <div class="flex items-baseline">
                                <p class="font-bold text-lg leading-8">{new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(property.price.amount)}</p>
                            </div>
                            {/* <div class="flex mt-1">
                                <div class="flex space-x-6 lg:space-x-4 truncate overflow-ellipsis">
                                    {property.property_type === "house" ? (<>
                                        <div>
                                            <p class="text-xs text-gray-600">Floor plan</p>
                                            <p class="text-sm font-medium truncate overflow-ellipsis">{property.layout.name}</p>
                                        </div>
                                        {true ? (<div class="tooltip">
                                            <p class="text-xs text-gray-600 border-b border-dashed border-red-600">Building size</p>
                                            <p class="text-sm font-medium truncate overflow-ellipsis">{property.sqm}m²</p>
                                            <div class="tooltip-text -mt-24 bg-gray-600 text-white text-xs shadow rounded">
                                                "Building size exceeds Floor Area Ratio<br/> 
                                                {// {property.floor_area_ratio} · {property.floor_area_sqm}m²</span> }
                                            </div>
                                        </div>):(
                                            <div class="tooltip">
                                            <p class="text-xs text-gray-600">Building size</p>
                                            <p class="text-sm font-medium {{if .Property.BulidingSizeExceedRatio}}border-b border-dashed border-red-600{{end}}">{property.sqm}m²</p>
                                        </div>
                                        )}
                                        <div>
                                            <p class="text-xs text-gray-600">Land size</p>
                                            <p class="text-sm font-medium">{property.land.sqm}m²</p>
                                        </div>
                                    </>): null}

                                    { //HasBCRandFARtrue? }
                                    <div>
                                        <p class="text-xs text-gray-600">間取り</p>
                                        <p class="text-sm font-medium truncate overflow-ellipsis">3LDK</p>
                                    </div>
                                    <div class="tooltip">
                                        <p class="text-xs text-gray-600">建物面積</p>
                                        <p class="text-sm font-medium ">110.34m²</p>
                                    </div>
                                    <div>
                                        <p class="text-xs text-gray-600">土地面積</p>
                                        <p class="text-sm font-medium">417.00m²</p>
                                    </div>
                                    <div class="tooltip">
                                        <p class="text-xs text-gray-600">建ぺい率/容積率</p>
                                        <p class="text-sm font-medium">40%/200%</p>
                                        <div class="tooltip-text -mt-32 -ml-12 bg-gray-600 text-white text-xs shadow rounded">
                                            <p>建ぺい率</p>
                                            <p class="mb-1">166.80m²</p>
                                            <p>容積率</p>
                                            <p>834.00m²</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>)
}