import React from "react"
import TopNavBar from '../Shared/TopNavBar'
import * as api from '../../services/admin'
import Select from 'react-select'
import { Translate } from "../../translate/Translate";
import Error from '../Shared/Error'
import { PhotoManager } from "../Shared/PhotoManager";
import { useLocation } from "react-router-dom";


export default function NewHomeExample(props) {
    
    const photographyBusiness = props.businessCompanies.filter((company) => company.businesses.includes("company_businesses_photography"))
    const builderBusiness = props.businessCompanies.filter((company) => company.businesses.includes("company_businesses_house_builder"))
    const architectBusiness = props.businessCompanies.filter((company) => company.businesses.includes("company_businesses_architect"))
    const [showError, setShowError] = React.useState(false);
    const [showLocation, setShowLocation] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [showPhotoManager, setShowPhotoManager] = React.useState(false);
    const [mapLoaded, setMapLoaded] = React.useState(false);
    const [mapMarker, setMapMarker] = React.useState(null);
    const search = useLocation().search;
    const dataFromQueryString = new URLSearchParams(search).get('data') ? JSON.parse(new URLSearchParams(search).get('data')) : null
    
    const [latlng, setLatLng] = React.useState({
        "latitude": "",
        "longitude": ""
    });

    const selectStyles = {
        control: base => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: 'none'
        })
    };

    const defaultFeatures = {}
    defaultFeatures["house"] =  [
        {
            "key": "Building type",
            "value": "",
        },
        {
            "key": "Parking space",
            "value": "",
        },
    ]


    const [data, setData] = React.useState({
        "area": dataFromQueryString !== null ? dataFromQueryString.area : {
            "id": "karuizawa",
        },
        "sqm": 250,
        "address": {},
        "latlng": {
            "latitude": 36.3418518,
            "longitude": 138.6179432
        },
        "land": {
            "sqm": 0
        },
        "images": [],
        "features": defaultFeatures[dataFromQueryString?.property_type || "house"],
        "built": {
            "month": 11,
            "year": 2020
        },
        "title": "",
        "overview": "",
        "price": {
            "amount": "",
            "currency": "JPY",
            "updated_at": "2020-12-02T06:13:58.769377Z"
        },
        "designed_by": {},
        "built_by": {},
        "photography_by": {},
        "floor": "",
        "remarks": "",
        "archived_images": [],
    });

    //this is when a user tap on a map
    React.useEffect(() => {
        if (latlng.latitude === "" || latlng.longitude === "") {
            return;
        }
        setData({ ...data, latlng: latlng });
    }, [latlng]);

    //this is when a user enter value directy to the input text field
    React.useEffect(() => {
        if (data.latlng.latitude === "" || data.latlng.longitude === "" || mapLoaded === false) {
            return;
        }
        let location = { lat: parseFloat(data.latlng.latitude), lng: parseFloat(data.latlng.longitude) }
        mapMarker.setPosition(location)
        fetchAddress(location)
    }, [data.latlng]);


    const initMap = (lat, lng) => {
        let karuizawaLatLng = { lat: lat, lng: lng };
        let marker = null;
        const map = new window.google.maps.Map(document.getElementById('map'), {
            center: karuizawaLatLng,
            zoom: 12
        });

        map.addListener("center_changed", () => {
            var center = map.getCenter();
            var latitude = center.lat();
            var longitude = center.lng();
        });

        map.addListener("click", (mapsMouseEvent) => {
            let latlng = mapsMouseEvent.latLng;
            marker.setPosition(latlng)
            let location = {
                latitude: latlng.toJSON().lat,
                longitude: latlng.toJSON().lng,
            }
            setLatLng(location);
        });

        marker = new window.google.maps.Marker({
            position: karuizawaLatLng,
            map: map,
            title: ''
        });
        setMapMarker(marker)
        fetchAddress(karuizawaLatLng)
    }

    const fetchAddress = (location) =>{
        new window.google.maps.Geocoder()
        .geocode({ location: location })
        .then((response) => {
          if (response.results[0]) {
            let address = {}
            var address_components = response.results[0].address_components
            for (var i in address_components) {
                for (var j in address_components[i].types) {
                    switch (address_components[i].types[j]) {
                        case "postal_code":
                            address.zip_code = address_components[i].short_name
                            break;
                        case "administrative_area_level_2":
                            address.district = address_components[i].short_name
                            break;
                        case "administrative_area_level_1":
                            address.prefecture = address_components[i].short_name
                            break;
                        case "route":
                            address.address_2 = address_components[i].short_name
                            break;
                        case "premise":
                            address.address_1 = address.address_1 ? address.address_1 + " " + address_components[i].short_name : address_components[i].short_name
                            break;
                        case "sublocality_level_2":
                            address.address_1 = address.address_1 ? address.address_1 + " " + address_components[i].short_name : address_components[i].short_name
                            break;
                        case "sublocality_level_3":
                            address.address_1 = address.address_1 ? address.address_1 + " " + address_components[i].short_name : address_components[i].short_name
                            break;
                        case "locality":
                            address.city = address_components[i].short_name
                            break;
                        case "country":
                            address.country_code = address_components[i].short_name
                            break;
                        default:
                    }
                }
            }
            setData({ ...data, address: address })
          } else {
            console.log("No results found");
          }
        })
        .catch((e) => console.log("Geocoder failed due to: " + e));
    }

    React.useEffect(() => {
        if (!window.google) {
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyABOxKjDvLfT42OEWorBcqzTfVPHPaTElE&libraries=&v=weekly&region=JP&language=ja`;
            var x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
            // Below is important. 
            // We cannot access google.maps until it's finished loading
            s.addEventListener('load', e => {
                setMapLoaded(true)
            })
        } else {
            setMapLoaded(true)
        }
    }, []);

    React.useEffect(() => {
        for (var index = 0; index < data.features.length; index++) {
            let f = data.features[index];
            let featureData = featureDataByKey(f.key)
            if (featureData !== null) {
                data.features[index].key = featureData.key;
                data.features[index].title = featureData.key;
                data.features[index].index = featureData.index;
                data.features[index].data = featureData;
                if (featureData.type === "list") {
                    data.features[index].value = featureData.values[0].value;
                }
            }
            data.features[index] = { ...data.features[index], ["title"]: f.title }
        }

        setData({ ...data, features: data.features })
    }, [props.featureList]);

    React.useEffect(() => {
        if (mapLoaded) {
            initMap(data.latlng.latitude, data.latlng.longitude)
        }
    }, [mapLoaded]);


    const handleInputChange = (e) => {
        var { name, value, min, max, selectionStart,selectionEnd,oldSelectionStart,oldSelectionEnd } = e.target

        oldSelectionStart = selectionStart;
        oldSelectionEnd = selectionEnd;
        
        value = e.target.dataset.type === "float" ? value.normalize('NFKC').replace(/。/g, ".").replace(/[^0-9.]/g, "") : value.normalize('NFKC').replace(/[^0-9]/g, "")

        if (value.lastIndexOf(".") !== value.indexOf("."))
            value = value.slice(0, -1)

        if (value && max && e.target.dataset.type === "int" && parseFloat(value) > max) {
            value = max
        }

        if (value && min && e.target.dataset.type === "int" && parseFloat(value) < min) {
            value = min
        }

        //if name is set like "land.sqm" then we assign it like below
        if (name.includes(".")) {
            let fields = name.split(".")
            let object = fields[0];
            let fieldName = fields[1];
            setData({ ...data, [object]: { ...data[object], [fieldName]: e.target.dataset.type === "int" ? value ? parseFloat(value) : 0 : value } })
            return;
        }
        setData({ ...data, [name]: e.target.dataset.type === "int" ? value ? parseFloat(value) : 0 : value })
        e.target.setSelectionRange(oldSelectionStart, oldSelectionEnd);
    }

    const handleDataChange = (e) => {

        var { name, value } = e.target
        if (e.target.dataset.type === "number") {
            if (e.type === "change") {
                value = parseFloat(value)
            } else if (e.type === 'blur' || e.key === 'Enter') {
                value = parseFloat(value)
            }
        }
        //if name is set like "land.sqm" then we assign it like below
        if (name.includes(".")) {
            let fields = name.split(".")
            let object = fields[0];
            let fieldName = fields[1];
            setData({ ...data, [object]: { ...data[object], [fieldName]: value } })
            return;
        }

        console.log(props.defaultFeatures)
        console.log(props.defaultFeatures[value])
        
        console.log(name)
        setData({ ...data, [name]: value })
    }

    const featureDataByKey = (key) => {
        const result = props.featureList.filter(f => f.key === key)

        if (result.length === 1) {
            return result[0]
        }
        return null;
    }

    const handleFeatureTitleChange = (feature, index) => {
        let featureData = featureDataByKey(feature.key)
        if (featureData !== null) {
            data.features[index].key = featureData.key
            data.features[index].title = featureData.key
            data.features[index].index = featureData.index
            data.features[index].data = featureData;
            if (featureData.type === "list") {
                data.features[index].value = featureData.values[0].value;
            }
        }
        setData({ ...data, features: data.features })
    }

    const addFeature = () => {
        let feature = { index: 0, title: "", value: "" }
        //default data to first in the list of feature list
        let first = props.featureList[0];
        feature.data = first
        feature.key = first.key;
        feature.title = first.key;
        feature.index = first.index;
        if (first.type === "list") {
            feature.value = first.values[0].value;
        }

        if (data.feature === null) {
            data.feature = []
        }
        data.features.push(feature)
        setData({ ...data, features: data.features })
    }

    const removeFeature = (index, feature) => {
        data.features.splice(index, 1)
        setData({ ...data, features: data.features })
    }

    const handleFeatureValueChange = (index, e) => {
        var { name, value } = e.target;
        data.features[index] = { ...data.features[index], [name]: value }
        setData({ ...data, features: data.features })
    }

    const handleBusiness = (type, selected) => {
        setData({ ...data, [type]: selected})
    }

    const createNewHomeExample = (property) => {
        let data = {...property}
        if (data.sqm)
            data.sqm = parseFloat(data.sqm)

        if (data.land && data.land.sqm)
            data.land.sqm = parseFloat(data.land.sqm)

        if (!showLocation) {
            data.latlng = {}
            data.address = {}
        }
        
        api.createNewHomeExample(data)
            .then(response => {
                props.history.replace(`/example/${response.id}`);
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error.data.message)
            })
    }

    const onSubmit = (e) => {
        e.preventDefault();

        const {built_by} = data
        if (!built_by.id) {
            document.getElementById("built_by").focus()
            setTimeout(() => {
                document.getElementById("designed_by").style.border = '1px solid black'
            }, 3000)
            console.log("built_by is empty")
            return
        }
        
        createNewHomeExample(data)
    }

    const renderFeatureValue = (feature, index) => {
        if (feature.data === null || feature.data === undefined) {
            return <input required name="value" onChange={(e) => handleFeatureValueChange(index, e)} value={feature.value} type="text" className="rounded w-full" />;
        }

        if (feature.data.type !== "list") {
            return <input required name="value" onChange={(e) => handleFeatureValueChange(index, e)} value={feature.value} type="text" className="rounded w-full" />
        }

        if (feature.data.type === "list") {
            return (
                <select className="rounded w-full" name="value" onChange={(e) => handleFeatureValueChange(index, e)} value={feature.value}>
                    {
                        feature.data.values.map((value, index) => (
                            <option key={index} value={value.value}>{value.title}</option>
                        ))
                    }
                </select>
            )
        }
    }


    const handleZipCodeChange = (e) => {
        let address = data.address
        address.zip_code = e.target.value
        setData({ ...data, address:address  })
    }
    const handleCityChange = (e) => {
        let address = data.address
        address.city = e.target.value
        setData({ ...data, address:address  })
    }
    const handleDistrictChange = (e) => {
        let address = data.address
        address.district = e.target.value
        setData({ ...data, address:address  })
    }
    const handlePrefectureChange = (e) => {
        let address = data.address
        address.prefecture = e.target.value
        setData({ ...data, address:address  })
    }
    const handleAddress2Change = (e) => {
        let address = data.address
        address.address_2 = e.target.value
        setData({ ...data, address:address  })
    }

    const handleAddress1Change = (e) => {
        let address = data.address
        address.address_1 = e.target.value
        setData({ ...data, address:address  })
    }
    
    return (
        <div className="">
            <PhotoManager setData={setData} data={data} tags={props.tags} images={data.images} isPresented={showPhotoManager} dismiss={(e) => { setShowPhotoManager(false) }}></PhotoManager>
            <TopNavBar active="listing"></TopNavBar>
            <div className="mt-16 mb-16 w-full container mx-auto py-8 px-4">

                <div className="mb-8 flex justify-between items-center">
                    <div>
                        <div className="flex flex-row mb-1">
                            <button type="button" onClick={(e) => { props.history.goBack() }} className="text-xs uppercase font-medium text-gray-600 inline-flex items-center">
                                <svg className="fill-current text-gray-600 mr-2" width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.890625 5.76953C0.890625 5.91602 0.943359 6.04492 1.05469 6.15625L5.70117 10.6973C5.80078 10.8027 5.92969 10.8555 6.08203 10.8555C6.38672 10.8555 6.62109 10.627 6.62109 10.3223C6.62109 10.1699 6.55664 10.041 6.46289 9.94141L2.19727 5.76953L6.46289 1.59766C6.55664 1.49805 6.62109 1.36328 6.62109 1.2168C6.62109 0.912109 6.38672 0.683594 6.08203 0.683594C5.92969 0.683594 5.80078 0.736328 5.70117 0.835938L1.05469 5.38281C0.943359 5.48828 0.890625 5.62305 0.890625 5.76953Z" />
                                </svg>
                                <span>{Translate("Listing")}</span>
                            </button>
                        </div>

                        <p className="text-2xl font-semibold">{Translate("Create new Home example")}</p>
                    </div>
                </div>
                <form onSubmit={onSubmit}>
                    <div className="w-full mt-4">
                        <div className="grid grid-cols-1 divide-y text-sm shadow rounded-md bg-white">

                            <div className="col-span-1 py-6  px-6">
                                <div className="flex items-center">
                                    <div>
                                        <p className="text-xl font-medium">{Translate("Property info")}</p>
                                        <p className="text-xs">{Translate("This information will be displayed publicly on the website once you published.")}</p>
                                    </div>
                                    <div className="ml-auto">
                                        {/* <button className="btn btn-primary">{Translate("Publish")}</button> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3 items-center">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Example title")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <input required name="title" value={data.title} onChange={handleDataChange} type="text" placeholder={Translate("Tsurudamari Residence")} className="rounded w-full" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Photos")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-1">
                                        <button type="button" className="px-3 py-2 rounded text-sm bg-blue-600 text-white inline-flex items-center mr-3"  onClick={e=>setShowPhotoManager(true)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                                </svg>
                                                <span>{Translate("Manage Photos")} ({data.images.length})</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3 items-top">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Size")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <div className="grid grid-cols-2 gap-4 mb-2">
                                            <div className="col-span-1">
                                                <label htmlFor="sqm" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Floor area")}</label>
                                                <input name="sqm" value={data.sqm === 0 ? "" : data.sqm}
                                                    data-type="float"
                                                    onChange={handleInputChange}
                                                    onBlur={handleInputChange}
                                                    onKeyUp={handleInputChange}
                                                    type="text"
                                                    step="0.01"
                                                    placeholder="800.5" className="rounded w-full" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Built")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <div className="grid grid-cols-2 gap-4 mb-2">
                                            <div className="col-span-1">
                                                <label htmlFor="built.month" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Month")}</label>
                                                <input name="built.month" value={data.built.month === 0 ? "" : data.built.month} onChange={handleInputChange} data-type="int" type="text" pattern="\d*" min="1" max="12" placeholder="10" className="rounded w-full" />
                                            </div>
                                            <div className="col-span-1">
                                                <label htmlFor="built.year" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Year")}</label>
                                                <input name="built.year" value={data.built.year === 0 ? "" : data.built.year} onChange={handleInputChange} data-type="int" type="text" pattern="\d*" placeholder="2019" className="rounded w-full" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3 items-center">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Price")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <div className="relative">
                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <span className="font-medium text-gray-600">
                                                    ¥
                                                </span>
                                            </div>
                                            <input name="price.amount" 
                                                value={data.price.amount.trim().replaceAll(",","").replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                data-type="price"
                                                onChange={handleInputChange}
                                                onBlur={handleInputChange}
                                                onKeyUp={handleInputChange}
                                                type="text"
                                                placeholder="50,000,000" className="pl-7 rounded w-full" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Overview")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <textarea required rows="6" name="description" value={data.description} onChange={handleDataChange} placeholder={Translate("Describe this property")} className="rounded w-full" >
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Design By")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <Select
                                            styles={selectStyles}
                                            className="text-base border border-gray-600 rounded "
                                            id="designed_by"
                                            name="designed_by"
                                            options={architectBusiness}
                                            getOptionLabel={({ name }) => name}
                                            getOptionValue={({ id }) => id}
                                            onChange={(value) => {
                                                handleBusiness("designed_by", value)
                                            }}
                                            value={architectBusiness.filter(({id}) => id === data.designed_by.id)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Built By")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <Select
                                            required={true}
                                            styles={selectStyles}
                                            className="text-base border border-gray-600 rounded "
                                            id="built_by"
                                            name="built_by"
                                            options={builderBusiness}
                                            getOptionLabel={({ name }) => name}
                                            getOptionValue={({ id }) => id}
                                            onChange={(value) => {
                                                handleBusiness("built_by", value)
                                            }}
                                            value={builderBusiness.filter(({id}) => id === data.built_by.id)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Photograph By")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <Select
                                            styles={selectStyles}
                                            className="text-base border border-gray-600 rounded "
                                            id="photography_by"
                                            name="photography_by"
                                            options={photographyBusiness}
                                            getOptionLabel={({ name }) => name}
                                            getOptionValue={({ id }) => id}
                                            onChange={(value) => {
                                                handleBusiness("photography_by", value)
                                            }}
                                            value={photographyBusiness.filter(({id}) => id === data.photography_by.id)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1 py-4 pt-8 lg:pb-4 px-6">
                                <p className="text-lg font-medium">{Translate("Home example details")}</p>
                                <p className="text-sm text-gray-700">{Translate("Details of this property. e.g. Architecture, Zoning, Terrain, etc.")}</p>
                            </div>

                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Features")}</p>
                                    </div>

                                    <div className="col-span-3 lg:col-span-2">
                                    {data.features.map((feature, index) => (
                                        <div key={index} className="grid grid-cols-2 gap-4 gap-y-0 mb-2 items-center">

                                            <div className="col-span-1">
                                                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Feature title")}</label>
                                                <Select
                                                    styles={selectStyles}
                                                    className="text-base border border-gray-600 rounded "
                                                    name="title"
                                                    options={props.featureList}
                                                    getOptionLabel={({ title }) => title}
                                                    getOptionValue={({ key }) => key}
                                                    onChange={(value) => handleFeatureTitleChange(value, index)}
                                                    value={props.featureList.filter(({ key }) => key === feature.key)}
                                                />
                                            </div>

                                            <div className="col-span-1">
                                                <div className="flex items-center">
                                                    <div className="w-full">
                                                        <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Value")}</label>
                                                        {renderFeatureValue(feature, index)}
                                                    </div>
                                                    <div className="ml-auto pl-2">
                                                        <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">&nbsp;</label>
                                                        <button type="button" onClick={(e) => removeFeature(index, feature)} className="focus:outline-none text-xs font-medium text-red-500">
                                                            <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        ))}
                                        <button type="button" className="focus:outline-none text-xs font-medium text-indigo-600 mb-2" onClick={(e) => addFeature()}>+ {Translate("Add feature")}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1 py-4 pt-8 lg:pb-4 px-6">
                                <div className="grid grid-cols-3 cursor-pointer" onClick={() => {
                                            setShowLocation(!showLocation)
                                        }}>
                                    <div className="col-span-3 lg:col-span-1">
                                        <span className="inline-block">
                                        {showLocation ? 
                                            (<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M15.707 4.293a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L10 8.586l4.293-4.293a1 1 0 011.414 0zm0 6a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L10 14.586l4.293-4.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                            </svg>): 
                                            (<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                                <path fill-rule="evenodd" d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                            </svg>)}
                                            
                                        </span>
                                        <p className="inline-block text-lg font-medium">
                                        {Translate("Location")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        
                                    </div>
                                </div>
                                <div className="grid grid-cols-3" style={{display: showLocation ? "block": "none"}}>
                                    <div className="col-span-3 lg:col-span-1">
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <div className="grid grid-cols-2 gap-4 mb-2">
                                            <div className="col-span-1">
                                                <label htmlFor="latlng.latitude" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Latitude")}</label>
                                                <input required name="latlng.latitude" value={data.latlng.latitude}
                                                    data-type="float"
                                                    onChange={handleInputChange}
                                                    onBlur={handleInputChange}
                                                    onKeyUp={handleInputChange}
                                                    type="text"
                                                    step="0.001"
                                                    placeholder={data.latlng.latitude} className="rounded w-full" />
                                            </div>
                                            <div className="col-span-1">
                                                <label htmlFor="latlng.longitude" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Longitude")}</label>
                                                <input required name="latlng.longitude" value={data.latlng.longitude}
                                                    data-type="float"
                                                    onChange={handleInputChange}
                                                    onBlur={handleInputChange}
                                                    onKeyUp={handleInputChange}
                                                    type="text"
                                                    step="0.001"
                                                    placeholder={data.latlng.longitude} className="rounded w-full" />
                                            </div>
                                            <div className="col-span-2">
                                                <div id="map" className="h-96 w-full bg-gray-300 rounded"></div>
                                                <p className="text-sm mt-2 text-gray-600">{Translate("Click on the map to assign property location")}</p>
                                            </div>
                                            <div className={"col-span-2 mt-4 grid grid-cols-1 lg:grid-cols-2 gap-4 " }>    
                                                <div className="lg:col-span-2">
                                                    <p>{Translate("Address")}</p>
                                                    <p className="text-xs text-gray-600">{Translate("address_remark")}</p>
                                                </div>
                                                <div className="lg:col-span-1">
                                                        <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("Zipcode")}</label>
                                                        <input type="text" placeholder="" onChange={handleZipCodeChange} value={data.address.zip_code} className="rounded w-full" />
                                                </div>         

                                                <div className="lg:col-span-1">
                                                    <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("Prefecture")}</label>
                                                    <input type="text" placeholder="" onChange={handlePrefectureChange} value={data.address.prefecture} className="rounded w-full" />
                                                </div>

                                                <div className="lg:col-span-1">
                                                        <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("District")}</label>
                                                        <input type="text" placeholder="" onChange={handleDistrictChange} value={data.address.district} className="rounded w-full" />
                                                </div>

                                                <div className="lg:col-span-1">
                                                        <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("City")}</label>
                                                        <input type="text" placeholder="" onChange={handleCityChange} value={data.address.city} className="rounded w-full" />
                                                </div>

                                                <div className="lg:col-span-1">
                                                        <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("Address 1")}</label>
                                                        <input type="text" placeholder="" onChange={handleAddress1Change} value={data.address.address_1} className="rounded w-full" />
                                                </div>

                                                <div className="lg:col-span-1">
                                                        <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("Address 2")}</label>
                                                        <input type="text" placeholder="" onChange={handleAddress2Change} value={data.address.address_2} className="rounded w-full" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span1 py-2 bg-gray-200 border-t px-6 flex items-center">
                                <div className="py-2 ml-auto">
                                    <button type="submit" className="btn btn-primary">{Translate("Publish")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Error isPresented={showError} errorMessage={errorMessage} dismiss={(e) => { setShowError(false) }}></Error>
        </div>
    )
}