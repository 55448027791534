import React from "react"
import LangContext from "../LangContext"
import {locales} from "../locales/Locales"


export function Translate(key) {    
    const {language} = React.useContext(LangContext)
    const value = locales[language][key];
    if (value === undefined) {
        return key;
    }
    return value.length === 0 ? key : value;
}

export function TranslateWithLang(lang,key) {  
    if (lang === undefined)
        return  
    if (key === undefined)
        return  
    const value = locales[lang][key];
    if (value === undefined) {
        return key;
    }
    return value.length === 0 ? key : value;
}