
import React from "react"
import { Link } from 'react-router-dom';
import moment from "moment";
import { Translate } from "../../translate/Translate";
import * as Constants from '../../constants'
export function CompanyRow(props) {
    const formatTime = (t) => {
        return moment(t).fromNow()
    }

    const [optionsVisible, setOptionsVisible] = React.useState(false);

    const toggleOptionsMenu = (e) => {
        setOptionsVisible(!optionsVisible)
    }

    /*
{
    "id": "5af52f5f-992d-411a-a979-baa5a7e8b2aa",
    "example_no": "HM001",
    "title": "T-Villa",
    "description": "軽井沢の中でもひときわ静かなエリアに建つ２階建ての邸宅。\nクライアントご家族は３０年以上も前からこの地で軽井沢の夏を楽しみ過ごされてきました。５００坪近い広大な敷地には、軽井沢の町樹でもあるコブシ、ホオノキ、白樺等、種々様々な木が植えられており、ご家族と共に年月を重ね、またそれらの移ろいを楽しみに毎年の夏を過ごされておりました。\n\t\n風景に調和し、今までの建物の雰囲気も醸しながら、ご家族の皆様にいつまでも愛されるよう【繋ぎ、継ぐ】ことをテーマに設計を進めました。\n\t\nリビング空間を中心に、それぞれの家族のプライベートを保ちつつ共に夏の軽井沢をお過ごし頂けるよう、庭を取り囲むようにそれぞれの個室を設けています。個室の一つは渡り廊下で繋がる「離れ」のような計画とし、既存樹の大きなコブシを横に見ながら母屋との行き来が出来るよう計画しました。\n\t\n「本体」「渡り廊下」「離れ」の３つの建物がそれぞれ斜めに繋がるような平面構成ではあるものの、耐震等級は最高等級３を実現。\n温熱環境としては北海道レベルの断熱性能を実現し、春夏秋冬のいつでも快適に集まれる家族の場所となりました。",
    "images": [
        {
            "id": "9e9c7fe3-f862-4746-a52e-3f0648740932",
            "image_no": "IM0007207",
            "url": "https://www.karuizawakenchiku.jp/_src/49523353/4.webp?v=1627439446394",
            "alt": "",
            "tags": null,
            "source": "",
            "reference": {
                "id": "",
                "title": "",
                "area": ""
            }
        },
    ],
    "area": {
        "id": "karuizawa",
        "name": "",
        "latlng": {},
        "title": "",
        "subtitle": "",
        "image_url": "",
        "keywords": null
    },
    "built": {
        "month": 9,
        "year": 2020
    },
    "designed_by": {
        "id": "CN113",
        "company_no": "",
        "name": "軽井沢建築社",
        "url": "",
        "image_url": "",
        "cover_image_url": "",
        "phone": "",
        "about": "",
        "contact_email": "",
        "businesses": null,
        "google_data": {...},
        "service_areas": null,
        "example_amount": 0
    },
    "built_by": {
        "id": "CN115",
        "company_no": "",
        "name": "アーデンホームズ",
        "url": "",
        "image_url": "",
        "cover_image_url": "",
        "phone": "",
        "about": "",
        "contact_email": "",
        "businesses": null,
        "google_data": {...},
        "service_areas": null,
        "example_amount": 0
    },
    "photography_by": {
        "id": "CN114",
        "company_no": "",
        "name": "大槻茂",
        "url": "",
        "image_url": "",
        "cover_image_url": "",
        "phone": "",
        "about": "",
        "contact_email": "",
        "businesses": null,
        "google_data": {...},
        "service_areas": null,
        "example_amount": 0
    },
    "size": 250,
    "price": {
        "amount": "50000000",
        "currency": "JPY",
        "updated_at": "0001-01-01T00:00:00Z"
    },
    "tag": [
        "design"
    ],
    "features": [
        {
            "title": "Building Materials",
            "value": "Wood"
        },
        {
            "title": "Parking space",
            "value": "2"
        }
    ],
    "is_in_user_ideas_book": false
}
    */

    return (

        <tr>
            <td className="px-6 py-4 text-center whitespace-no-wrap text-sm leading-5">
                <input type="checkbox" checked={props.checked} onChange={e=>props.onChange(props.data.id)} className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
            </td>
            <td className="px-6 py-4 whitespace-no-wrap">
                <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                        {
                            props.data.image_url === "" ?
                                <div className="h-10 w-10 rounded object-cover border border-dashed border-gray-600 overflow-hidden"></div>
                                :
                                <img className="h-10 w-10 rounded object-cover" src={props.data.image_url} alt="" />
                        }
                    </div>
                    <div className="ml-4">
                        <div className="text-xs font-medium inline-flex items-center">
                            {props.data.company_no}
                            <a href={`${Constants.BASE_URL}/companies/${props.data.company_no}`} target="_blank" rel="noreferrer">
                                <div className="ml-4 inline-flex items-center text-blue-600">
                                    <span>VIEW ON VIILA</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-1 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <div className=" font-medium text-indigo-700 truncate text-sm">
                            <Link to={'/company/' + props.data.id}>
                                {props.data.name}
                            </Link>
                        </div>
                        <div className=" font-medium text-indigo-700 truncate text-sm flex">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                            </svg>
                            {props.data.phone}
                        </div>
                    </div>
                    <div className="ml-6 flex space-x-4">
                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
                <div className="flex">
                {props.data.businesses.map((business) => (<p className="bg-blue-50 m-1 font-semibold rounded text-indigo-700 flex">{business.replace("company_businesses_","")}</p>))}
                </div>
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
                <div className="flex">
                {props.data.service_areas.map((area) => (<p className="bg-blue-50 m-1 font-semibold rounded text-indigo-700 flex">{area}</p>))}
                </div>
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-center ">
                <p className="text-xs">{Translate("Created")} : {/*formatTime(props.data.created_at)*/}</p>
                <p className="text-xs">{Translate("Updated")} : {/*formatTime(props.data.updated_at)*/}</p>
            </td>
            <td className="px-6 py-4 relative">
                <div className={"overflow-hidden origin-top-right z-50 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 " + (optionsVisible === true ? "" : "hidden")}>
                    <div className="bg-white" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <button type="button" onClick={(e) => { toggleOptionsMenu() }} className="bg-white w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{Translate("Cancel")}</button>
                    </div>
                </div>
                <button onClick={(e) => { toggleOptionsMenu() }} className="z-10 focus:outline-none">
                    <svg className="fill-current text-gray-700" width="4" height="15" viewBox="0 0 4 15" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.86719 11.7188C1 11.7188 0.304688 12.4219 0.304688 13.3047C0.304688 14.1641 1 14.8672 1.86719 14.8672C2.73438 14.8672 3.42969 14.1641 3.42969 13.3047C3.42969 12.4219 2.73438 11.7188 1.86719 11.7188ZM1.86719 5.9375C1 5.9375 0.304688 6.63281 0.304688 7.5C0.304688 8.35938 1 9.05469 1.86719 9.05469C2.73438 9.05469 3.42969 8.35938 3.42969 7.5C3.42969 6.63281 2.73438 5.9375 1.86719 5.9375ZM1.86719 0.132812C1 0.132812 0.304688 0.828125 0.304688 1.69531C0.304688 2.57812 1 3.27344 1.86719 3.27344C2.73438 3.27344 3.42969 2.57812 3.42969 1.69531C3.42969 0.828125 2.73438 0.132812 1.86719 0.132812Z" />
                    </svg>
                </button>
            </td>
        </tr>

    )
}

