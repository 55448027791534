import React, {useState,useEffect} from "react"
import * as api from '../../services/admin'
import TopNavBar from '../Shared/TopNavBar'
import { Translate } from "../../translate/Translate";
import { Link } from 'react-router-dom';
import * as Constants from '../../constants'
import { LabelGroupRow } from "./LabelGroupRow";
import Select from 'react-select'
import { LabelGroupModal } from "./LabelGroupModal";
import Error from '../Shared/Error'

export default function Listing(props) {
    const query = new URLSearchParams(window.location.search);

    const selectStyles = {
        control: base => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: 'none'
        })
    };
    const [modalOpen, setModalOpen] = useState(false)
    const [tag, setTag] = useState({})
    const [tags, setTags] = useState([])
    const [labels, setLabels] = useState([])
    const [filters, setFilters] = useState(JSON.parse(query.get("query") ?? `{
        "labels": []
    }`))

    function loadTags() {
        api.fetchTagLabel()
            .then((response) => {
                console.log("response", response.data)
                setTags(response.data)
            })
    }

    function loadPhotoLabels(type) {
        api.fetchPhotoLabels(type)
            .then((response) => {
                setLabels(response.sort((x,y) => y.count - x.count))
            })
    }

    function handleFilterChange({target}) {
        // { target: { name: "page", value: photos.current_page - 1 } }
        const { name, value } = target
        setFilters({ ...filters, [name]: value })
    }

    function removeLabelGroup({index}) {
        if (index < 0) return
        tag.label_group.splice(index, 1)
        api.createTagLabel({
            name: filters.tag,
            label_group: tag.label_group,
        }).then((response) => {
            setFilters({ ...filters, labels: [], photo_forward: undefined, page:1})
            window.location.reload()
        })
    }

    useEffect(() => {
        if (filters.photo_forward) {
            setModalOpen(true)
        }
        props.history.replace({
            pathname: window.location.pathname,
            search: `?query=${JSON.stringify(filters).replace("&","%26")}`
        })
    }, [filters])

    useEffect(() => {
        if (tags.length !== 0 && filters.tag) {
            setTag(tags.find(({name}) => filters.tag === name))
        }
    }, [tags])


    useEffect(() => {
        loadTags()
        loadPhotoLabels("home")
    }, [])

    return (
        <div className="">
            <TopNavBar active="label"></TopNavBar>
            <div className="mt-16 w-full container mx-auto py-8 px-4">
                <LabelGroupModal {...props} isOpen={modalOpen} setIsOpen={setModalOpen} tag={tag} filters={filters} setFilters={setFilters} labels={labels} tags={tags} />
                <div className="mb-8 flex justify-between items-center">
                    <div>
                        <p className="text-2xl font-semibold">{Translate("Listing")}</p>
                    </div>
                </div>
                <div id="filter-section" className="w-full mb-8">
                    {tags.map((tag) => (
                        <p key={tag.name} onClick={() => {
                            setTag(tag)
                            setFilters({...filters, tag: tag.name, page:1})
                        }} className={`px-3 py-2 rounded text-sm ${filters.tag === tag.name ? "bg-green-600": "bg-blue-600"} text-white inline-block mr-1 mb-1 cursor-default`}>
                            {tag.name} 
                        </p>
                    ))}
                </div>
                <div id="pager" className="w-full">

                    <div className="mb-4 flex items-center justify-between">

                        
                        <div className="ml-auto">
                        </div>
                    </div>
                </div>
                <div className="w-full flex">
                    <div class="w-1/3 relative mr-3 h-full">
                        {tag.images ? <img class="object-cover w-full h-60" src={tag.images[0].url} />: <div class="w-full h-60"></div>}
                        <div class="absolute top-0 left-0 bg-black bg-opacity-50 w-full h-full flex items-end p-8">
                            <div>
                                <p class="text-2xl text-white font-medium mb-4">{tag.name}</p>
                            </div>
                        </div>
                    </div>
                    <div class="w-full">
                        <div className="mb-8 flex justify-between items-center">
                            <div>
                            </div>
                            <div className={`btn btn-primary inline-flex cursor-pointer items-center ${!filters.tag? "bg-gray-300 hover:bg-gray-400": ""}`} onClick={(e) => {
                                if(!filters.tag) {
                                    e.preventDefault()
                                } else {
                                    setModalOpen(true)
                                }
                            }}>
                                <svg className="w-4 h-4 mr-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 13.8594C7.28906 13.8594 7.53125 13.625 7.53125 13.3359V7.90625H12.8047C13.0859 7.90625 13.3281 7.66406 13.3281 7.375C13.3281 7.08594 13.0859 6.84375 12.8047 6.84375H7.53125V1.40625C7.53125 1.11719 7.28906 0.882812 7 0.882812C6.71094 0.882812 6.46875 1.11719 6.46875 1.40625V6.84375H1.20312C0.914062 6.84375 0.671875 7.08594 0.671875 7.375C0.671875 7.66406 0.914062 7.90625 1.20312 7.90625H6.46875V13.3359C6.46875 13.625 6.71094 13.8594 7 13.8594Z" fill="white" />
                                </svg>
                                <span>{Translate("Add Label Group")}</span>
                            </div>
                        </div>

                        
                        <div class="shadow-lg transition duration-500 ease-in-out transform rounded-lg cursor-pointer m-auto">

                            <table className="w-full divide-y divide-gray-200">
                                <thead className="bg-gray-100">
                                    <tr>
                                        <th className="px-2 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                            <input checked={false} type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                                        </th>
                                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("Label Group")}</th>
                                        <th className="px-6 py-3 bg-gray-50"></th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {(tag?.label_group || []).map((group, index) => <LabelGroupRow data={{labels: group, name:tag.name, index}} markAsDelete={removeLabelGroup} />)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}