import React, {useCallback, useState} from "react"
import TopNavBar from '../Shared/TopNavBar'
import Select from 'react-select'
import * as api from '../../services/admin'
import { Translate } from "../../translate/Translate";
import { useDropzone } from 'react-dropzone'
import Error from '../Shared/Error'
import { useEffect } from "react";


export default function NewHomeExample(props) {
    
    const [showError, setShowError] = useState(false);
    const [mapLoaded, setMapLoaded] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [placeQuery, setPlaceQuery] = useState("");
    const [place, setPlace] = useState({});
    const {allAreas} = props
    const businesses = ["company_businesses_architect",
        "company_businesses_photography",
        "company_businesses_house_builder",
        "company_businesses_house_reform",
        "company_businesses_exterior_constuction",
        "company_businesses_logging",
        "company_businesses_management",
        "company_businesses_agent",
    ].map(value => ({
        name: value,
        id: value,
    }))

    const [data, setData] = useState({
        "name": "",
        "url": "",
        "image_url": "",
        "cover_image_url": "",
        "phone": "",
        "contact_email": "",
        "businesses": [],
        "google_data": {
            "place_id": "",
            "place_detail": {}
        },
        "service_areas": [],
    });

    const selectStyles = {
        control: base => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: 'none'
        })
    };

    const initMap = () => {
        const map = new window.google.maps.Map(document.getElementById('map'), {
            center: {lat: 36.3418518,lng:138.6179432},
            zoom: 12
        });

        const autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('place_query'))

        autocomplete.bindTo("bounds", map)
        // Specify just the place data fields that you need.
        autocomplete.setFields(["place_id", "geometry", "name","rating","review","user_ratings_total","url","formatted_address","geometry","plus_code","address_component","website","opening_hours"]);

        const marker = new window.google.maps.Marker({ map: map })

        autocomplete.addListener("place_changed", () => {
            console.log("data", data)
            const place = autocomplete.getPlace();

            if (!place.geometry || !place.geometry.location) {
                setPlaceQuery(document.getElementById('place_query').value)
                return;
            }

            if (place.geometry.viewport) {
                map.fitBounds(place.geometry.viewport);
            } else {
                map.setCenter(place.geometry.location);
                map.setZoom(17);
            }
            // Set the position of the marker using the place ID and location.
            marker.setPlace({
                placeId: place.place_id,
                location: place.geometry.location,
            });

            marker.setVisible(true);
            setPlaceQuery(document.getElementById('place_query').value)
            setPlace(place)
        })
    }

    useEffect(() => {
        setData({...data, google_data: place})
    }, [place])

    useEffect(() => {
        if (!window.google) {
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyABOxKjDvLfT42OEWorBcqzTfVPHPaTElE&libraries=places&v=weekly&region=JP&language=ja`;
            var x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
            // Below is important. 
            // We cannot access google.maps until it's finished loading
            s.addEventListener('load', e => {
                setMapLoaded(true)
            })
        } else {
            setMapLoaded(true)
        }
    }, []);

    useEffect(() => {
        if (mapLoaded) {
            initMap()
        }
    }, [mapLoaded]);

    const onCoverDrop = useCallback(acceptedFiles => {
        // Do something with the files
        console.log("acceptedFiles", acceptedFiles)

        if (acceptedFiles.length >0) {
            const file = acceptedFiles[0]
            //add fill temp
            const reader = new FileReader();
            reader.onload = function (event) {
                var imageData = event.target.result;
                var base64Data = imageData.split("base64,")[1];
                api.uploadImage(base64Data)
                    .then(response => {
                        setData({ ...data, cover_image_url: response.data })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
            reader.readAsDataURL(file);
        }
    }, [data])
    const onProfileDrop = useCallback(acceptedFiles => {
        // Do something with the files
        console.log("acceptedFiles", acceptedFiles)

        if (acceptedFiles.length >0) {
            const file = acceptedFiles[0]
            //add fill temp
            const reader = new FileReader();
            reader.onload = function (event) {
                var imageData = event.target.result;
                var base64Data = imageData.split("base64,")[1];
                api.uploadImage(base64Data)
                    .then(response => {
                        setData({ ...data, image_url: response.data })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
            reader.readAsDataURL(file);
        }
    }, [data])

    const profileDropZone = useDropzone({
        onDrop: onProfileDrop,
        accept: 'image/jpeg, image/png',
        multiple: false
    });
    const coverDropZone = useDropzone({
        onDrop: onCoverDrop,
        accept: 'image/jpeg, image/png',
        multiple: false
    });

    const handleDataChange = (e) => {

        var { name, value } = e.target
        if (e.target.dataset.type === "number") {
            if (e.type === "change") {
                value = parseFloat(value)
            } else if (e.type === 'blur' || e.key === 'Enter') {
                value = parseFloat(value)
            }
        }
        //if name is set like "land.sqm" then we assign it like below
        if (name.includes(".")) {
            let fields = name.split(".")
            let object = fields[0];
            let fieldName = fields[1];
            setData({ ...data, [object]: { ...data[object], [fieldName]: value } })
            return;
        }

        setData({ ...data, [name]: value })
    }

    const createNewCompany = (property) => {
        let data = {...property}
        
        api.createNewCompany(data)
            .then(response => {
                props.history.replace(`/company/${response}`);
            })
            .catch(error => {
                console.log("error", error)
                setShowError(true)
                setErrorMessage(error.data.message)
            })
    }

    const onSubmit = (e) => {
        e.preventDefault();

        createNewCompany(data)
    }

    return (
        <div>
            <TopNavBar active="company"></TopNavBar>
            <div className="mt-16 mb-16 w-full container mx-auto py-8 px-4">

                <div className="mb-8 flex justify-between items-center">
                    <div>
                        <div className="flex flex-row mb-1">
                            <button type="button" onClick={(e) => { props.history.goBack() }} className="text-xs uppercase font-medium text-gray-600 inline-flex items-center">
                                <svg className="fill-current text-gray-600 mr-2" width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.890625 5.76953C0.890625 5.91602 0.943359 6.04492 1.05469 6.15625L5.70117 10.6973C5.80078 10.8027 5.92969 10.8555 6.08203 10.8555C6.38672 10.8555 6.62109 10.627 6.62109 10.3223C6.62109 10.1699 6.55664 10.041 6.46289 9.94141L2.19727 5.76953L6.46289 1.59766C6.55664 1.49805 6.62109 1.36328 6.62109 1.2168C6.62109 0.912109 6.38672 0.683594 6.08203 0.683594C5.92969 0.683594 5.80078 0.736328 5.70117 0.835938L1.05469 5.38281C0.943359 5.48828 0.890625 5.62305 0.890625 5.76953Z" />
                                </svg>
                                <span>{Translate("Listing")}</span>
                            </button>
                        </div>

                        <p className="text-2xl font-semibold">{Translate("Create new Company")}</p>
                    </div>
                </div>
                <form onSubmit={onSubmit}>
                    <div className="w-full mt-4">
                        <div className="grid grid-cols-1 divide-y text-sm shadow rounded-md bg-white">

                            <div className="col-span-1 py-6  px-6">
                                <div className="flex items-center">
                                    <div>
                                        <p className="text-xl font-medium">{Translate("Company info")}</p>
                                        <p className="text-xs">{Translate("This information will be displayed publicly on the website once you published.")}</p>
                                    </div>
                                    <div className="ml-auto">
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="w-full h-40 lg:h-96">
                                    <div className="w-full h-40 lg:h-96 object-cover bg-gray-500">
                                        {data.cover_image_url === "" ? <div className="w-full h-40 lg:h-96 object-cover"></div>: (<div className="relative">
                                            <img className="w-full h-40 lg:h-96 object-cover" src={data.cover_image_url} alt="profile" />
                                            <button className="absolute top-0 right-0 bg-gray-600 text-white p-2 rounded  m-2" onClick={() => {
                                                setData({...data, cover_image_url: ""})
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                        </div>)}
                                        <div className="relative">
                                            <div className="absolute bottom-0 right-0 mr-2 mb-2 mx-auto text-xs btn btn-primary" {...coverDropZone.getRootProps()}  style={{ cursor: 'pointer' }} >
                                                <input className="w-full h-full" {...coverDropZone.getInputProps()} />
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                                                </svg>
                                                {Translate("Edit Cover")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full flex px-4">
                                    <div className=" transform w-24 h-24 -mt-12 lg:w-40 lg:h-40 lg:-mt-20 rounded bg-gray-200  border-2 lg:border-4 ring-1 ring-gray-200 border-white">
                                        <div {...profileDropZone.getRootProps()} className="w-full h-full flex-none border rounded border-blue-600 text-blue-600 inline-flex items-center" style={{ cursor: 'pointer'}} >
                                            <div className="mx-auto text-xs" >
                                                {data.image_url === "" ? (<>
                                                    <input className="w-full h-full" {...profileDropZone.getInputProps()} />
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                                                    </svg>
                                                </>): (<>
                                                    <div>
                                                        <img className="w-full h-full" src={data.image_url} alt="profile" />
                                                        <button className="absolute top-0 right-0 bg-gray-600 text-white p-2 rounded  m-2" onClick={() => {
                                                            setData({...data, image_url: ""})
                                                        }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3 items-center">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Company title")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <input required name="name" value={data.name} onChange={handleDataChange} type="text" placeholder={Translate("Tsurudamari Residence")} className="rounded w-full" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3 items-center">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Website URL")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <input required name="url" value={data.url} onChange={handleDataChange} type="text" placeholder={Translate("Tsurudamari Residence")} className="rounded w-full" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3 items-center">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Phone")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <input required name="phone" value={data.phone} onChange={handleDataChange} type="text" placeholder={Translate("Phone")} className="rounded w-full" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3 items-center">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Email")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <input required name="contact_email" value={data.contact_email} onChange={handleDataChange} type="email" placeholder={Translate("Email")} className="rounded w-full" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3 items-center">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Businesses")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <Select
                                            styles={selectStyles}
                                            isMulti
                                            className="text-base border border-gray-600 rounded "
                                            id="businesses"
                                            name="businesses"
                                            options={businesses}
                                            getOptionLabel={({ name }) => name}
                                            getOptionValue={({ id }) => id}
                                            onChange={(value) => {
                                                console.log("value.map(({id}) => id)", value.map(({id}) => id))
                                                setData({...data, businesses: value.map(({id}) => id)})
                                            }}
                                            value={businesses.filter(business => data.businesses.includes(business.id))}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1 py-4 lg:py-4 px-6">
                                <div className="grid grid-cols-3 items-center">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Service Areas")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <Select
                                            styles={selectStyles}
                                            isMulti
                                            className="text-base border border-gray-600 rounded "
                                            id="service_areas"
                                            name="service_areas"
                                            options={allAreas}
                                            getOptionLabel={({ name }) => name}
                                            getOptionValue={({ id }) => id}
                                            onChange={(value) => {
                                                setData({...data, service_areas: value.map(({id}) => id)})
                                            }}
                                            value={allAreas.filter(area => data.service_areas.includes(area.id))}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-3 py-4 lg:py-4 px-6">
                                <div className="col-span-3 lg:col-span-1">
                                    <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Google Place")}</p>
                                </div>
                                <div className="col-span-3 lg:col-span-2">
                                    <div className="grid grid-cols-2 gap-4 mb-2">
                                        <div className="col-span-3 lg:col-span-2">
                                            <input required id="place_query" name="place_query" value={placeQuery} onChange={(e) => {
                                                setPlaceQuery(e.target.value)
                                            }} type="text" placeholder={Translate("Google Place")} className="rounded w-full" />
                                        </div>
                                        <div className="col-span-2">
                                            <div id="map" className="h-96 w-full bg-gray-300 rounded"></div>
                                            <p className="text-sm mt-2 text-gray-600">{Translate("Click on the map to assign property location")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-span1 py-2 bg-gray-200 border-t px-6 flex items-center">
                                <div className="py-2 ml-auto">
                                    <button type="submit" className="btn btn-primary">{Translate("Publish")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Error isPresented={showError} errorMessage={errorMessage} dismiss={(e) => { setShowError(false) }}></Error>
        </div>
    )
}